import styled from 'styled-components'
import colors from '../../assets/style/colors'

const ChartsStyle = styled.div`
	min-height: 100vh;
	${'' /* padding: 30px; */}
	${'' /* padding-top: 30px; */}
	background-size: cover;
	${'' /* box-sizing: border-box; */}
	background-position: center center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	.text {
		color: green;
	}
	.loader {
		margin-top: 150px;
		margin-left: 50%;
	}
	.container {
		min-height: 92vh;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		box-shadow: 3px 3px 10px #cfcfcf;
		${'' /* border-radius: 10px; */}
		padding: 30px;
		background-color: #fffffff2;
		.img {
			width: 150px;
			height: 150px;
			margin-top: 10px;
		}
		.card {
			width: 100%;
			margin-bottom: 50px;
			text-align: center;
			.label {
				font-size: 20px;
				color: ${colors.mainColor};
			}
			.input {
				border: 2px solid ${colors.gray};
				font-size: 20px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				margin-top: 10px;
			}
		}
	}

	.leftCard {
		width: 600px;
	}
	.rightCard {
		width: 60%;
	}
	.topTitle {
		text-align: center;
		margin: 0;
		font-weight: bold;
		font-size: 20px;
		width: 100%;
		margin-bottom: 10px;
	}
	.table {
		border-collapse: collapse;
		margin-top: 20px;
		background-color: #ffffff;
		width: 100%;
		td,
		th {
			border: 1px solid ${colors.gray};
			padding: 5px;
		}
	}
	.greenRaw {
		background-color: #e6ffe0;
	}
	.redRaw {
		background-color: #ffe0e0;
	}
	.redRaw:hover {
		background-color: #fea3a3;
		cursor: pointer;
	}
	.greenRaw:hover {
		background-color: #a3feab;
		cursor: pointer;
	}
	.searchCard {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		.input {
			border: 2px solid ${colors.gray};
			font-size: 14px;
			padding: 10px;
			border-radius: 5px;
			color: #434343;
			width: 280px;
			margin-bottom: 20px;
		}
	}
	.productTitle {
		font-size: 25px;
		font-weight: bold;
		color: #434343;
		padding: 0;
		margin: 0;
		text-align: center;
	}

	.flex {
		display: flex;
		width: 80px;
		display: flex;
		padding: 0 10px;
		width: 100%;
		.btn {
			height: 35px;
			border-radius: 50px;
			margin-right: 10px;
		}
	}
	.topCard {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		.topCardMini {
			display: flex;
		}
		.topTitleInput {
			margin: 0;
		}
		.topInput {
			border: 2px solid ${colors.gray};
			font-size: 14px;
			padding: 10px;
			border-radius: 5px;
			color: #434343;
			width: 280px;
			height: 10px;
			margin-right: 20px;
		}
		.topInputDate {
			border: 2px solid ${colors.gray};
			font-size: 14px;
			padding: 10px;
			border-radius: 5px;
			color: #434343;
			width: 180px;
			height: 10px;
			margin-right: 20px;
		}
		.sellect {
			border: 2px solid ${colors.gray};
			font-size: 14px;
			padding: 5px 10px;
			border-radius: 5px;
			color: #434343;
			width: 180px;
			height: 33px;
			margin-right: 20px;
		}
	}
	.leftCard2 {
		width: 400px;
		height: 200px;
		border: 1px solid #d8d8d8;
		padding: 10px 0;
		border-radius: 5px;
	}
	.operatorTitle {
		margin: 0;
		text-align: center;
	}
	.between {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}
	.btnSome {
		padding: 10px 30px;
		border-radius: 5px;
		background-color: ${colors.mainColor};
		color: #ffffff;
		font-size: 15px;
		border: none;
		width: 500px;
	}
	.usd {
		color: #1aa910;
		font-weight: bold;
	}
	.progres {
		margin: 0;
		text-align: center;
		color: tomato;
	}
`
export default ChartsStyle
