import React, { useEffect, useRef, useState, useCallback } from 'react'
import ClientsStyle from './../Clients/Clients'
import Button from '../../components/Button'
import api from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import CreateIcloudModal from '../../components/Modal/CreateIcloud'
import UpdateUser from './UpdateIcloud'
import ClipLoader from 'react-spinners/ClipLoader'
import customMainApi from '../../api/index'
import { Empty } from 'antd'
import _ from 'lodash'
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'
import { useTranslation } from 'react-i18next'

const IcloudPage = () => {
	const errorRef = useRef()
	const updateRef = useRef()
	const createUserRef = useRef()
	const succesCreateUserRef = useRef()
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)
	const [icloud, SetIcloud] = useState('')
	const [icloudCode, SetIcloudCode] = useState('')
	const [page, setPage] = useState(0)
	const [userFields, setUserFields] = useState([])

	const [data, setData] = useState({
		data: [],
		currentPage: 0, //20
	})
	const [clientsData, setClientsData] = useState([])
	const search = () => {
		setIsLoading(true)
		let url =
			'Security?$select=Code,Name,U_Whichtelephone,U_Password,U_Status,U_TypeAccount&$orderby=Code desc'

		if (icloud.length >= 1 && icloudCode.length >= 1)
			url += `&$filter=contains(Name, '${icloud}') or contains(Code, '${icloud}')`
		url += `&$skip=${page * 10}`
		api
			.get(url, { headers: { Prefer: 'odata.maxpagesize=10' } })
			.then((res) => {
				const resData = JSON.parse(res.data).value
				setData({
					data: [...resData],
				})
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const businessPartners = async (value) => {
		try {
			const res = await api.get('Security', {
				params: {
					$select:
						'Code,Name,U_Whichtelephone,U_Password,U_Status,U_TypeAccount',
					$filter: `contains(Name, '${value}') or contains(Code, '${value}')`,
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			setClientsData(JSON.parse(res.data).value || [])
		} catch (err) {
			errorRef.current?.open(get(err, 'message', ''))
			setIsLoading(false)
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleBusinessPartner = useCallback(
		_.debounce((value) => {
			businessPartners(value)
		}, 1000),
		[],
	)
	useEffect(() => {
		search()
	}, [page])
	useEffect(() => {
		userFieldsFn()
	}, [])

	const viewItem = (v) => {
		updateRef.current?.open(v)
	}

	const newDatas = () => {
		if ((data.data.length = 10)) {
			setPage((prevState) => prevState + 1)
		} else {
			alert(t('noOtherInfo'))
		}
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
					$select: 'Name,ValidValuesMD,TableName',
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const oldData = () => {
		if (page === 0) {
			alert(t('noOtherInfo'))
		} else {
			setPage((prevState) => prevState - 1)
		}
	}

	return (
		<Layout>
			<ClientsStyle>
				<div className="container">
					<p className="productTitle ">{t('icloud')}</p>
					<div
						className="flex items-center justify-between flex-col sm:flex-row
						gap-5 mt-5
					"
					>
						{' '}
						<div className="flex gap-4 flex-col sm:flex-row w-full">
							<div className="relative">
								<input
									className={
										'border-[1px] border-[#d9d9d9] p-2 rounded-md w-full sm:w-auto'
									}
									type="text"
									placeholder={t('icloud')}
									value={icloudCode}
									onChange={(e) => {
										SetIcloudCode(e.target.value)
										handleBusinessPartner(e.target.value)
									}}
								/>
								{icloudCode && (
									<div className="absolute top-10 left-0 bg-white w-full z-10 mt-1 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
										{clientsData.map((v, i) => {
											return (
												<div
													key={i}
													className="p-2 border-b cursor-pointer hover:bg-gray-100"
													onClick={() => {
														SetIcloud(v.Name)
														setClientsData([])
													}}
												>
													{v.Name}
												</div>
											)
										})}
									</div>
								)}
							</div>

							<button
								className="h-10 bg-[#0A4D68] text-white px-4 py-2  rounded-md w-full sm:w-auto"
								onClick={() => search(0, 0)}
								isLoading={isLoading}
							>
								{t('search')}
							</button>
						</div>
						<button
							className="h-10 bg-[#0A4D68] text-white px-4 py-2 text-center  rounded-md w-full sm:w-auto"
							onClick={() => createUserRef.current?.open()}
						>
							{t('add2')}
						</button>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-auto mb-8 mt-8 ">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className=" text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												{t('code')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('icloud')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('phone')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('password')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('typeAccount')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('status')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
													onClick={() => viewItem(v)}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{get(v, 'Code', '')}
													</td>
													<td className="px-6 py-4">{get(v, 'Name', '')}</td>
													<td className="px-6 py-4">
														{formatPhoneNumber(get(v, 'U_Whichtelephone', ''))}
													</td>
													<td className="px-6 py-4">
														{get(v, 'U_Password', '')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'U_TypeAccount', '') !== ''
															? userFields
																	?.find((item) => item.Name === 'TypeAccount')
																	?.ValidValuesMD.find(
																		(value) =>
																			get(v, 'U_TypeAccount', '') ===
																			value.Value,
																	)?.Description || ''
															: ''}
													</td>
													<td className="px-6 py-4">
														{get(v, 'U_Status', '')}
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex items-center gap-2">
									<Button
										disabled={page === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</Button>
									<p>{page + 1}</p>
									<Button
										disabled={data.data.length !== 10}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</ClientsStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
			<UpdateUser
				getRef={(ref) => (updateRef.current = ref)}
				onUpdated={() => search(data.currentPage, data.data)}
			/>
			<CreateIcloudModal
				getRef={(r) => (createUserRef.current = r)}
				onConfirm={() => {
					succesCreateUserRef.current?.open('Аккаунт защиты успешно создан')
				}}
				onUpdated={() => search(data.currentPage, data.data)}
			/>
			{/* </> */}
		</Layout>
	)
}

export default IcloudPage
