import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../../components/Layout'
import { get } from 'lodash'
import Button from '../../../components/Button'
import { useSelector } from 'react-redux'
import customMainApi, { customFuncsApi } from '../../../api'
import { ErrorModal, SuccessModal } from '../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import MainModal from '../../../components/Modal/MainModal'
import CheckboxGroup from '../../../components/GroupCheckboxes'
import { useNavigate } from 'react-router-dom'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const CreateInventoryTransfers = () => {
	const { getMe } = useSelector((state) => state.main)
	const { t } = useTranslation()

	const navigate = useNavigate()
	const initData = {
		ItemCode: '',
		ItemName: '',
		Quantity: 0,
		SerialNumbers: [],
	}
	const errorRef = useRef()
	const successModalRef = useRef()
	const imeiRef = useRef()

	const [body, setBody] = useState([{ ...initData }])
	const [fromWarehouse, setFromWarehouse] = useState(getMe.U_Warehouse || '')
	const [docDate, setDocDate] = useState(moment().format('YYYY-MM-DD'))

	const [toWarehouse, setToWarehouse] = useState('')
	const [warehouses, setWarehouses] = useState([])
	const [btnLoading, SetBtnLoading] = useState(false)
	const [items, setItems] = useState([])
	const [imeis, setImeis] = useState([])
	const [itemLoading, setItemLoading] = useState(false)
	const [imeiLoading, setImeiLoading] = useState({
		loading: false,
		order: null,
	})
	const [warehouseLoading, setWarehouseLoading] = useState(false)
	const [selectedIemiOrder, setSelectedImeiOrder] = useState(null)

	const handleSelectSerialNumber = (imei, sysNumber) => {
		setBody((prev) => {
			const newBody = [...prev]
			newBody[selectedIemiOrder].SerialNumbers.push({ imei, sysNumber })
			return newBody
		})
	}

	const handleRemoveSerialNumber = (imei) => {
		setBody((prev) => {
			const newBody = [...prev]
			newBody[selectedIemiOrder].SerialNumbers = newBody[
				selectedIemiOrder
			].SerialNumbers.filter((item) => item.imei !== imei)
			return newBody
		})
	}

	const handleCheckboxGroupChange = (selectedValues) => {
		const newBody = [...body]
		newBody[selectedIemiOrder] = {
			...newBody[selectedIemiOrder],
			SerialNumbers: selectedValues.map((imei) => {
				const item = items.find((item) => item.IntrSerial === imei)
				return { imei, sysNumber: item.SysNumber }
			}),
		}
		setBody(newBody)
	}

	const handleAddItem = () => {
		setBody((prev) => [...prev, { ...initData }])
	}

	const handleRemoveItem = (i) => {
		setBody((prev) => prev.filter((_, idx) => idx !== i))
	}

	useEffect(() => {
		getWarehouses()
		if (fromWarehouse !== '') {
			getItems(fromWarehouse)
		}
	}, [fromWarehouse])

	const getImeis = (itemCode, whsCode, i) => {
		setImeiLoading({
			loading: true,
			order: i,
		})
		customFuncsApi
			.get(
				`xsSql/getItemSerialsByWhsCode?ItemCode='${itemCode}'&WhsCode='${whsCode}'`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=1000000',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value
				setImeis((prevState) => {
					const newImeis = [...prevState]
					newImeis[i] = [...resData]
					return newImeis
				})
			})
			.catch((err) => {
				errorRef.current?.open(JSON.stringify(err.response.data?.message))
			})
			.finally(() => {
				setImeiLoading({
					loading: false,
					order: null,
				})
			})
	}

	const getItems = (fromWarehouse) => {
		setItemLoading(true)
		customFuncsApi
			.get(
				`xsSql/getItemsByWarehouse?whsCode=${fromWarehouse}&isOnHand=${true}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=1000000',
					},
				},
			)
			.then((res) => {
				setItems(res.data.value)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
			.finally(() => {
				setItemLoading(false)
			})
	}

	const getWarehouses = () => {
		setWarehouseLoading(true)
		setBody([{ ...initData }])
		customMainApi
			.get('Warehouses?$select=WarehouseCode,WarehouseName')
			.then((res) => {
				setWarehouses(JSON.parse(res.data).value)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
			.finally(() => {
				setWarehouseLoading(false)
			})
	}

	const submit = () => {
		SetBtnLoading(true)
		customMainApi
			.post('StockTransfers', {
				DocDate: docDate,
				FromWarehouse: fromWarehouse,
				ToWarehouse: toWarehouse,
				SalesPersonCode: getMe.SalesPersonCode || -1,
				StockTransferLines: body.map((item) => {
					const { itemDescription, ItemName, ...rest } = item
					const SerialNumbers = item.SerialNumbers.map((imei) => ({
						InternalSerialNumber: imei.imei,
						SystemSerialNumber: imei.sysNumber,
					}))
					return {
						...rest,
						// WarehouseCode: toWarehouse,
						SerialNumbers,
					}
				}),
			})
			.then((res) => {
				successModalRef.current?.open('Транзакция успешно создана!')
				// setTimeout(() => {
				// 	window.location.reload()
				// }, 2000)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				SetBtnLoading(false)
			})
	}

	return (
		<Layout>
			<div className={'py-5 px-5 sm:py-10 sm:px-32'}>
				<h1 className={'font-medium text-xl text-center mb-4'}>
					{t('create-inventory')}
				</h1>
				{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ? (
					<div className="flex items-center justify-end w-full">
						<div className={'flex flex-col  '}>
							<label
								htmlFor="from-warehouse"
								className={'font-medium text-zinc-600 text-sm mb-1'}
							>
								{t('date')}
							</label>{' '}
							<input
								value={docDate}
								type={'date'}
								onChange={(e) => {
									setDocDate(e.target.value)
								}}
								className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] "
							/>
						</div>
					</div>
				) : null}
				<div className={'flex justify-between flex-col sm:flex-row gap-5'}>
					<div className={'flex flex-col min-w-[300px]'}>
						<label
							htmlFor="from-warehouse"
							className={'font-medium text-zinc-600 text-sm mb-1'}
						>
							{t('fromWhs')}
						</label>
						{get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? (
							<input
								value={
									warehouses.find(
										(item) => item.WarehouseCode === fromWarehouse,
									)?.WarehouseName
								}
								id={'from-warehouse'}
								className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] "
							/>
						) : (
							<>
								<div className={'flex items-center gap-1 w-full'}>
									<select
										id={'from-warehouse'}
										className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full"
										onChange={(e) => setFromWarehouse(e.target.value)}
										value={fromWarehouse}
									>
										<option value={null}>{t('selectWhs')}</option>
										{warehouses.map((v, i) => (
											<option key={i} value={v.WarehouseCode}>
												{v.WarehouseName}
											</option>
										))}
									</select>
									<ClipLoader size={15} loading={warehouseLoading} />
								</div>
								{!fromWarehouse && (
									<p className="text-red-500 text-sm">{t('whsRequired')} </p>
								)}
							</>
						)}
					</div>

					<div className={'flex flex-col min-w-[300px]'}>
						<label
							htmlFor="to-warehouse"
							className={'font-medium text-zinc-600 text-sm mb-1'}
						>
							{t('toWhs')}
						</label>
						<div className={'flex items-center gap-1 w-full'}>
							<select
								id={'to-warehouse'}
								className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full"
								onChange={(e) => setToWarehouse(e.target.value)}
								value={toWarehouse}
							>
								<option value={null}>{t('selectWhs')}</option>
								{warehouses
									.filter((v) => v.WarehouseCode !== fromWarehouse)
									.map((v, i) => (
										<option key={i} value={v.WarehouseCode}>
											{v.WarehouseName}
										</option>
									))}
							</select>
							<ClipLoader size={15} loading={warehouseLoading} />
						</div>
						{!toWarehouse && (
							<p className="text-red-500 text-sm">{t('whsRequired')} </p>
						)}
					</div>
				</div>
				<div className=" overflow-auto mb-16 mt-10">
					<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
						<thead className=" text-xs text-gray-700 uppercase bg-gray-50 ">
							<tr>
								<th scope="col" className="px-6 py-4">
									{t('product')}
								</th>

								<th scope="col" className="px-6 py-4">
									{t('quantity')}
								</th>
								<th scope="col" className="px-6 py-4 ">
									{t('imei')}
								</th>

								<th scope="col" className="px-6 py-4"></th>
							</tr>
						</thead>
						<tbody>
							{body.length > 0 ? (
								body.map((v, i) => {
									return (
										<tr
											className="bg-white border-b hover:bg-gray-50 h-[130px]"
											key={i}
										>
											<td className="px-6 py-4">
												<div className={'flex items-center gap-1 w-full'}>
													<input
														list={'items'}
														disabled={!fromWarehouse}
														className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-[200px] sm:w-full"
														value={v.ItemName}
														onChange={(e) => {
															if (
																items.find(
																	(item) =>
																		item.ItemCode ===
																		e.target.value.split(' , ')[1],
																)
															) {
																setBody((prev) => {
																	const newBody = [...prev]
																	newBody[i].ItemCode =
																		e.target.value.split(' , ')[1]
																	newBody[i].ItemName =
																		e.target.value.split(' , ')[0]
																	newBody[i].SerialNumbers = []
																	return newBody
																})
																getImeis(
																	e.target.value.split(' , ')[1],
																	fromWarehouse,
																	i,
																)
															} else {
																setBody((prev) => {
																	const newBody = [...prev]
																	newBody[i].ItemName = e.target.value
																	newBody[i].ItemCode = ''
																	newBody[i].SerialNumbers = []
																	return newBody
																})
																setImeis([])
															}
														}}
													/>
													<datalist id={'items'}>
														{items.map((v, i) => (
															<option key={i}>
																{`${v.ItemName} ${v.U_Memory !== null ? v.U_Memory : ''} ${v.U_Color !== null ? v.U_Color : ''} ${v.U_Condition !== null ? v.U_Condition : ''} , ${v.ItemCode}`}
															</option>
														))}
													</datalist>
													<ClipLoader size={15} loading={itemLoading} />
												</div>
											</td>

											<td className="px-6 py-4">
												<input
													value={v.Quantity}
													className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full"
													onChange={(e) => {
														const newBody = [...body]
														newBody[i].Quantity = e.target.value
														setBody(newBody)
													}}
													disabled={!fromWarehouse}
												/>
											</td>
											<td className="px-6 py-4 ">
												<div className={'flex items-center gap-1'}>
													<button
														onClick={() => {
															imeiRef.current?.open()
															setSelectedImeiOrder(i)
														}}
														className={`bg-[#FAFBFC] border ${
															body[i].SerialNumbers.length !== +body[i].Quantity
																? 'border-red-600'
																: 'border-[#DFE2E8]'
														} rounded-md p-3 text-sm text-[#1E1F1F] w-full focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:cursor-not-allowed`}
														disabled={
															(imeiLoading.order === i &&
																imeiLoading.loading) ||
															!imeis[i]
														}
													>
														{t('selectImei')}
													</button>
													{i === imeiLoading.order && (
														<ClipLoader
															size={15}
															loading={imeiLoading.loading}
														/>
													)}
												</div>
											</td>

											<td className={'w-[100px]'}>
												{i === body.length - 1 ? (
													<button
														onClick={handleAddItem}
														className="px-4 py-2  bg-[#0A4D68] text-white rounded-md ml-2"
													>
														+
													</button>
												) : null}
												{body.length > 1 ? (
													<button
														onClick={() => handleRemoveItem(i)}
														className="px-4 py-2 bg-red-600 text-white rounded-md ml-2"
													>
														-
													</button>
												) : null}
											</td>
										</tr>
									)
								})
							) : (
								<tr>
									<td colSpan="10">
										<Empty
											description={t('noInfo')}
											image={Empty.PRESENTED_IMAGE_SIMPLE}
										/>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<div className={'flex justify-end'}>
					<Button
						disabled={
							!toWarehouse ||
							!!body.find((item) => item.ItemCode === '') ||
							!!body.find((item) => item.SerialNumbers.length === 0) ||
							!!body.find((item) => item.Quantity === 0) ||
							btnLoading
						}
						isLoading={btnLoading}
						onClick={submit}
					>
						{t('create')}
					</Button>
				</div>
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<SuccessModal
					getRef={(r) => {
						successModalRef.current = r
					}}
					onConfirm={() => navigate('/inventory-transfer')}
				/>
				<MainModal getRef={(r) => (imeiRef.current = r)}>
					<div
						className={
							'min-w-[400px] max-w-[400px] max-h-[400px] min-h-[300px] overflow-y-scroll'
						}
					>
						<CheckboxGroup
							items={imeis[selectedIemiOrder] || []}
							selectedItems={body[selectedIemiOrder]?.SerialNumbers || []}
							quantity={body[selectedIemiOrder]?.Quantity || 0}
							onChange={handleCheckboxGroupChange}
							handleSelectSerialNumber={handleSelectSerialNumber}
							handleRemoveSerialNumber={handleRemoveSerialNumber}
						/>
					</div>
				</MainModal>
			</div>
		</Layout>
	)
}

export default CreateInventoryTransfers
