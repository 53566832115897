import React, { memo, useEffect, useState, useRef } from 'react'
import CheckByPhoneModalStyle from '../CheckByPhoneModal/CheckByPhoneModalStyle'
import Modal from 'react-modal'
import { ErrorModal } from '../index'
import { get } from 'lodash'
import { customFuncsApi } from '../../../api'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		maxHeight: '100vh',
		width: '85%',
	},
	overlay: {
		background: '#00000099',
	},
}

const ViewAccountModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
}) => {
	const { t } = useTranslation()

	const errorModalRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState([])
	const [accName, setAccName] = useState('')

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				search(t.accCode, t.startDate, t.endDate)
				setAccName(t.accName)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])
	const search = (accCode, startDate, endDate) => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`xsSql/getAccountTransactions?Account=${accCode}&date1=${startDate}&date2=${endDate}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=1000',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value
				setData(resData)
				setIsLoading(false)
			})
			.catch((err) => {
				if (err)
					errorModalRef.current?.open(
						get(err.response.data, 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CheckByPhoneModalStyle>
				{isLoading ? (
					<div className="flex items-center justify-center">
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					</div>
				) : (
					<>
						<div className="overflow-y-auto mb-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											{t('schotNum')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('schot')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('titleName')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('date')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('incoming-payment')}
										</th>
										<th scope="col" className="px-6 py-4">
											{t('outgoing-payment')}
										</th>
									</tr>
								</thead>
								<tbody>
									{data.length > 0 ? (
										data.map((v, i) => (
											<tr
												className="bg-white border-b  hover:bg-gray-50 "
												key={i}
											>
												<td className="px-6 py-4 font-medium text-gray-900 ">
													{get(v, 'TransId', '')}
												</td>

												<td className="px-6 py-4 whitespace-nowrap">
													{get(v, 'ShortName', '')}
												</td>
												<td className="px-6 py-4">{accName}</td>
												<td className="px-6 py-4">
													{moment(get(v, 'RefDate', '')).format('DD-MM-YYYY')}
												</td>
												<td className="px-6 py-4">
													{Number(get(v, 'FCDebit', '')) > 0
														? `${new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(get(v, 'FCDebit', ''))
																.replace(',', '.')} UZS`
														: `${new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(get(v, 'Debit', ''))
																.replace(',', '.')} USD`}
												</td>
												<td className="px-6 py-4">
													{Number(get(v, 'FCCredit', '')) > 0
														? `${new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(get(v, 'FCCredit', ''))
																.replace(',', '.')} UZS`
														: `${new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(get(v, 'Credit', ''))
																.replace(',', '.')} USD`}
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t('noInfo')}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</>
				)}

				<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
			</CheckByPhoneModalStyle>
		</Modal>
	)
}

export default memo(ViewAccountModal)
