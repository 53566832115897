import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorModal } from '../../components/Modal'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import { main } from '../../store/slices'
import LoginStyle from './LoginStyle'
import { get } from 'lodash'
import api, { customFuncsApi } from '../../api'
import EyeIcon from '../../assets/icons/eye'
import { useTranslation } from 'react-i18next'
import customMainApi from '../../api'

const Login = () => {
	const { setMe, setToken } = main.actions
	const { info } = useSelector((state) => state.main)
	const { t } = useTranslation()

	const loginValue = get(info, 'login', '')
	const passwordValue = get(info, 'password', '')

	const errorRef = useRef()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [login, setLogin] = useState(loginValue)
	const [password, setPassword] = useState(passwordValue)
	const [isLoading, setIsLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)

	useEffect(() => {
		if (window.location.hostname.includes('localhost')) {
			setLogin('boshliq')
			setPassword('Sal0mTest')
		}
	}, [])

	const loginProfile = useCallback(() => {
		setIsLoading(true)

		customFuncsApi
			.post('/login', {
				UserName: login,
				Password: password,
			})
			.then((res) => {
				dispatch(setToken(get(res, 'data.SessionId', '')))
				// const token = get(res, 'data.SessionId', '')
				
				// customFuncsApi.defaults.headers.common['Set-Cookie'] =
				// 	`sessionToken=${token}; SameSite=None; Secure`
				// customMainApi.defaults.headers.common['Set-Cookie'] =
				// 	`sessionToken=${token}; SameSite=None; Secure`
				// document.cookie = `authToken=${token}; path=/; SameSite=None; Secure;`
				secure()
			})
			.catch((err) => {
				errorRef.current?.open(t('loginError'))
				setIsLoading(false)
			})
	}, [login, password])

	useEffect(() => {
		const handleGlobalKeyDown = (e) => {
			if (e.key === 'Enter') {
				loginProfile()
			}
		}
		window.addEventListener('keydown', handleGlobalKeyDown)
		return () => {
			window.removeEventListener('keydown', handleGlobalKeyDown)
		}
	}, [loginProfile])

	const secure = () => {
		customFuncsApi
			.get(`userData`)
			.then((res) => {
				const resData = res.data
				if (get(resData, 'EmployeeID', '')) {
					if (get(resData, 'Department2.Name', '') === 'Sotuv') {
						navigate('/cash-payment')
					} else if (get(resData, 'Department2.Name', '') === 'Undiruv') {
						navigate('/recovery')
					} else if (get(resData, 'Department2.Name', '') === 'Sherik') {
						navigate('/all-products-partner')
					} else if (get(resData, 'Department2.Name', '') === 'Scoring') {
						navigate('/scoring-clients')
					} else if (get(resData, 'Department2.Name', '') === 'Undiruvchi1') {
						navigate('/recovery')
					} else if (get(resData, 'Department2.Name', '') === 'Undiruv_kocha') {
						navigate('/recovery')
					} else {
						navigate('/dashboard')
					}
					dispatch(setMe(resData))
				} else {
					errorRef.current?.open(t('loginError'))
				}
				setIsLoading(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
				setIsLoading(false)
			})
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			loginProfile()
		}
	}

	return (
		<div>
			<LoginStyle>
				<div className="py-10 px-6 rounded-md border-[1px] border-[#DFE2E8] bg-white w-full md:w-[350px]">
					<h2 className="text-[#4A545C] text-[20px] text-center">
						{t('login')}
					</h2>
					<div className="flex flex-col gap-4 mt-4">
						<div className="card">
							<div className="mb-1">
								<label
									htmlFor="login"
									className={'font-medium text-zinc-600 text-sm mb-1'}
								>
									{t('user')}
								</label>
							</div>
							<input
								type="text"
								id="login"
								placeholder={t('user')}
								value={login}
								onKeyDown={handleKeyDown}
								className="bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full"
								onChange={(e) => setLogin(e.target.value)}
							/>
						</div>
						<div className="card mb-5">
							<div className="mb-1">
								<label
									htmlFor="password"
									className={'font-medium text-zinc-600 text-sm mb-1'}
								>
									{t('password')}
								</label>
							</div>
							<div className="flex items-center bg-[#FAFBFC] border-[1px] border-[#DFE2E8] rounded-md p-3 text-[14px] text-[#1E1F1F] w-full">
								<input
									type={showPassword ? 'text' : 'password'}
									id="password"
									placeholder={t('password')}
									value={password}
									onKeyDown={handleKeyDown}
									className="flex-1 bg-[#FAFBFC] outline-none"
									onChange={(e) => setPassword(e.target.value)}
								/>
								<EyeIcon
									onClick={() => setShowPassword((prevState) => !prevState)}
									className="text-[#292D32] cursor-pointer"
								/>
							</div>
						</div>

						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								className="px-8 select-none"
								onClick={loginProfile}
								children={t('enter')}
								disabled={login.length < 1 || password.length < 1}
								isLoading={isLoading}
								hoverBtnStyle={{ width: 100 }}
							/>
						</div>
					</div>
				</div>
			</LoginStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
		</div>
	)
}

export default Login
