import styled from 'styled-components'
import colors from '../../assets/style/colors'
import BackImage from '../../assets/images/back.jpg'

const DashboardStyle = styled.div`
	min-height: 100vh;
	${'' /* padding: 20px; */}
	${'' /* padding-top: 30px; */}
	// background-image: url(${BackImage});
	background-size: cover;
	background-position: center center;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: flex-start;

	.container {
		flex: 1;
		padding: 20px;
		box-shadow: 3px 3px 10px #cfcfcf;
		border-radius: 10px;
		background-color: #fffffff2;
		padding: 10px;
		min-height: 92vh;

		.topCard {
			display: flex;
			justify-content: space-between;
			padding: 0 20px;
			margin-top: 10px;

			.flex {
				display: flex;
				width: 80px;
				justify-content: space-between;
			}

			.btn {
				align-items: center;
				width: 35px;
				height: 35px;
				padding: 0;
				border-radius: 50px;
			}
		}

		.table {
			border-collapse: collapse;
			margin-top: 20px;
			background-color: #ffffff;
			width: 100%;

			td,
			th {
				border: 1px solid ${colors.gray};
				padding: 5px;
			}

			.row {
				background-color: #ffffff;
			}

			.row:hover {
				background-color: #a3fea6;
				cursor: pointer;
			}
		}

		.searchCard {
			display: flex;
			margin-bottom: 10px;
			gap: 30px;
			.input {
				border: 2px solid ${colors.gray};
				padding: 10px;
				color: #434343;
				width: 280px;
				margin-bottom: 20px;
			}
		}

		.productTitle {
			font-size: 25px;
			font-weight: bold;
			color: #434343;
			padding: 0;
			text-align: center;
		}
	}

	.invoice-container {
		display: flex;
		gap: 30px;
		flex-wrap: wrap;
		justify-content: center;
		padding-top: 20px;

		.invoice-card {
			${'' /* width: fit-content; */}
			${'' /* padding: 20px; */}
			border-radius: 16px;
			box-shadow:
				0 10px 15px -3px rgb(0 0 0 / 0.1),
				0 4px 6px -4px rgb(0 0 0 / 0.1);
		}

		.type {
			font-size: 14px;
			padding: 5px;
			margin: 0;
			border-radius: 6px;
		}

		.income {
			background-color: #f6ffed;
			color: #3aa013;
			border: 1px solid #3aa013;
		}

		.withdraw {
			background-color: #fdf1f0;
			color: #d22736;
			border: 1px solid #d22736;
		}

		.price {
			text-align: center;
			font-size: 25px;
			font-weight: 600;
			padding: 0;
			margin-top: 25px;
		}
	}
`
export default DashboardStyle
