import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import api, { customFuncsApi } from '../../../api'
import { ErrorModal } from '../index'
import ClipLoader from 'react-spinners/ClipLoader'
import ImeiModal from './ImeiModal/index'
import { useTranslation } from 'react-i18next'
import { Empty } from 'antd'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		width: '90%',

		maxHeight: '100vh', // To make modal content scrollable if it exceeds viewport height
		// overflowY: 'auto',
	},
	overlay: {
		background: '#00000099',
	},
}

const InventoryTransfersModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const { t } = useTranslation()

	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const imeiModalRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const ref = {
			open: (data) => {
				// setDocEntry(data.DocEntry)
				getOneStockTransfers(data)
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const getOneStockTransfers = (docEntry) => {
		setLoading(true)
		customFuncsApi
			.get(`xsSql/getInventoryTransfer?DocEntry=${docEntry}`)
			.then((res) => {
				const data = res.data.value
				setData(data)
			})
			.catch((err) => {
				errorModalRef.current?.open(
					JSON.stringify(err.response.data?.details?.message),
				)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<div className="card">
				{/* <p className="title">
						{'Создать пользователя'}
						{'!'}
					</p> */}

				{loading ? (
					<div className="flex items-center justify-center">
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					</div>
				) : (
					<div className=" w-full overflow-x-auto mb-8 ">
						<table className="w-full text-sm text-left text-gray-500 ">
							<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
								<tr>
									<th scope="col" className="px-6 py-4">
										{t('clientCode')}
									</th>
									<th scope="col" className="px-6 py-4">
										{t('itemDescription')}
									</th>
									<th scope="col" className="px-6 py-4">
										{t('quantity')}
									</th>
									<th scope="col" className="px-6 py-4">
										{t('imei')}
									</th>

									<th scope="col" className="px-6 py-4">
										{t('price2')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? (
									data.map((v, i) => {
										return (
											<tr
												key={i}
												// onClick={() => viewItem(v)}
												className="bg-white border-b  hover:bg-gray-50 "
											>
												<td
													scope="row"
													className="px-6 py-4 font-medium text-gray-900 "
												>
													{get(v, 'ItemCode', '-')}
												</td>
												<td className="px-6 py-4">
													{get(v, 'ItemName', '-')} {v['U_Color.Descr']}{' '}
													{get(v, 'U_Memory', '')}
													{get(v, 'U_Condition', '')}
												</td>
												<td
													className="px-6 py-4"
													// onClick={() => {
													// 	imeiModalRef.current.open(get(v, 'IntrSerial', '-'))
													// }}
												>
													1get
												</td>
												<td
													className="px-6 py-4"
													// onClick={() => {
													// 	imeiModalRef.current.open(get(v, 'IntrSerial', '-'))
													// }}
												>
													{get(v, 'IntrSerial', '-')}
												</td>

												<td className="px-6 py-4">
													{new Intl.NumberFormat('fr-FR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													})
														.format(get(v, 'CostTotal', '-'))
														.replace(',', '.')}{' '}
												</td>
											</tr>
										)
									})
								) : (
									<tr>
										<td colSpan="10">
											<Empty
												description={t('noInfo')}
												image={Empty.PRESENTED_IMAGE_SIMPLE}
											/>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				)}
			</div>
			<ImeiModal getRef={(r) => (imeiModalRef.current = r)} />

			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default InventoryTransfersModal
