function formatPhoneNumber(phoneNumbersString) {
	if (!phoneNumbersString) {
		return ''
	}

	const phoneNumbers = phoneNumbersString.split(',')

	return phoneNumbers
		.map((phoneNumber) => {
			const trimmedPhone = phoneNumber.trim()
			if (trimmedPhone.length === 0 || trimmedPhone.length === 1) {
				return trimmedPhone // Return as is if length is 0 or 1
			}

			const cleaned = trimmedPhone.replace(/\D/g, '')

			if (cleaned.length === 12) {
				const match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/)
				if (match) {
					return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]} ${match[5]}`
				}
			} else if (cleaned.length === 9) {
				const match = cleaned.match(/^(\d{2})(\d{3})(\d{2})(\d{2})$/)
				if (match) {
					return `(${match[1]}) ${match[2]} ${match[3]} ${match[4]}`
				}
			} else if (cleaned.length === 7) {
				const match = cleaned.match(/^(\d{3})(\d{2})(\d{2})$/)
				if (match) {
					return `${match[1]} ${match[2]} ${match[3]}`
				}
			}

			return cleaned || trimmedPhone
		})
		.join(', ')
}
export default formatPhoneNumber
