import React, { useRef, useEffect, useState } from 'react'
import { ErrorModal, SuccessModal } from '../../components/Modal/'
import { useSelector } from 'react-redux'
import moment from 'moment'
import api, { customFuncsApi } from '../../api/index'
import Layout from '../../components/Layout/index'
import { get } from 'lodash'
import Button from '../../components/Button/index'
import { useTranslation } from 'react-i18next'

const RefundS = () => {
	const errorRef = useRef()
	const succesModal = useRef()
	const { t } = useTranslation()

	const { getMe } = useSelector((state) => state.main)
	const [form, setForm] = useState({
		DocDate: moment().format('YYYY-MM-DD'),
		Type: 'Покупка',
		CashSumUzs: '',
		CashSumUsd: '',
	})
	const [dollarAccounts, setDollarAccounts] = useState([])
	const [uzsAccounts, setUzsAccounts] = useState([])
	const [dollarCash, setDollarCash] = useState('')
	const [uzsCash, setUzsCash] = useState('')
	const [btnLoading, setBtnLoading] = useState(false)

	const [stateCurrencyRate, setStateCurrencyRate] = useState('')

	useEffect(() => {
		getDollarAccounts()
		getUZSAccounts()
		// getDollarRate()
	}, [])

	const handleRefund = ({ DocDate, Type, CashSumUzs, CashSumUsd }) => {
		setBtnLoading(true)
		customFuncsApi
			.post(`addMoneyChange`, {
				DocDate: DocDate,
				Type: Type === 'Покупка' ? 'UsdToUzs' : 'UzsToUsd',
				CashSumUzs: +CashSumUzs,
				CashSumUsd: +CashSumUsd,
				DocRate: +stateCurrencyRate,
				CashUzsAccount:
					get(getMe, 'Department2.Name', '') === 'Boshqaruv'
						? uzsCash
						: getMe.U_CashUzsAccount,
				CashUsdAccount:
					get(getMe, 'Department2.Name', '') === 'Boshqaruv'
						? dollarCash
						: getMe.U_CashAccount,
			})
			.then(() => {
				succesModal.current?.open('Конвертация успешно произведен!')
			})
			.catch((err) => {
				errorRef.current?.open(`${err.response.data.message}`)
			})
			.finally(() => {
				setBtnLoading(false)
			})
	}

	// const getDollarRate = () => {
	// 	api
	// 		.post(`SBOBobService_GetCurrencyRate`, {
	// 			Currency: 'USD',
	// 			Date: form.DocDate,
	// 		})
	// 		.then((res) => {
	// 			const resData = get(JSON.parse(res.data), 'value', [])
	// 			setStateCurrencyRate(get(resData, 0))
	// 		})
	// 		.catch((err) => {
	// 			errorRef.current?.open(
	// 				get(JSON.parse(err.response.data), 'error.message', ''),
	// 			)
	// 		})
	// }

	const getDollarAccounts = () => {
		// setIsLoading(true)
		// ?$filter=startswith(AccountName,'5')
		api
			.get(
				`ChartOfAccounts?$select=Code,Name,AcctCurrency&$filter=startswith(Code,'5') and AcctCurrency eq 'USD' `,
			)
			.then((res) => {
				const resData = JSON.parse(res.data).value || []
				setDollarAccounts(resData)
				// setIsLoading(false)
			})
		// .catch((err) => {
		// 	errorRef.current?.open(get(err, 'message', ''))
		// 	// setIsLoading(false)
		// })
	}

	const getUZSAccounts = () => {
		// setIsLoading(true)
		// ?$filter=startswith(AccountName,'5')
		api
			.get(
				`ChartOfAccounts?$select=Code,Name,AcctCurrency&$filter=startswith(Code,'5') and AcctCurrency eq 'UZS'`,
			)
			.then((res) => {
				const resData = JSON.parse(res.data).value || []
				setUzsAccounts(resData)
				// setIsLoading(false)
			})
		// .catch((err) => {
		// 	errorRef.current?.open(get(err, 'message', ''))
		// 	// setIsLoading(false)
		// })
	}

	const handleTypeChange = (e) => {
		const newType = e.target.value
		setForm((prevState) => {
			if (newType === 'Покупка') {
				const usdValue =
					prevState.CashSumUsd ||
					(prevState.CashSumUzs / stateCurrencyRate).toFixed(2)
				return {
					...prevState,
					Type: newType,
					CashSumUsd: usdValue,
					CashSumUzs: (usdValue * stateCurrencyRate).toFixed(2),
				}
			} else {
				const uzsValue =
					prevState.CashSumUzs ||
					(prevState.CashSumUsd * stateCurrencyRate).toFixed(2)
				return {
					...prevState,
					Type: newType,
					CashSumUzs: uzsValue,
					CashSumUsd: (uzsValue / stateCurrencyRate).toFixed(2),
				}
			}
		})
	}

	const handleSumChange = (e) => {
		const value = e.target.value
		setForm((prevState) => {
			// if (prevState.Type === 'Покупка') {
			return {
				...prevState,
				CashSumUsd: value,
				CashSumUzs: (value * stateCurrencyRate).toFixed(2),
			}
			// } else {
			// 	return {
			// 		...prevState,
			// 		CashSumUzs: value,
			// 		CashSumUsd: (value / stateCurrencyRate).toFixed(2),
			// 	}
			// }
		})
	}

	return (
		<Layout>
			{/* <Refund> */}
			<div className="w-full">
				{/* <h2 className={'text-center mb-5'}>{'Сдача деньги'}</h2> */}
				<div className="m-5">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-5">
						<div className="flex flex-col gap-2">
							<p>{t('date')}</p>
							<input
								type="date"
								placeholder="Date"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[250px]'
								}
								value={form.DocDate}
								onChange={(v) =>
									setForm((prevState) => ({
										...prevState,
										DocDate: v.target.value,
									}))
								}
							/>
						</div>
						<div className="flex flex-col gap-2">
							<p>{t('dollarCourse')}</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[250px]'
								}
								value={stateCurrencyRate}
								onChange={(v) => setStateCurrencyRate(v.target.value)}
							/>
							{!stateCurrencyRate && (
								<span className="text-red-500">
									{t('dollarCourseRequired')}
								</span>
							)}
						</div>
					</div>
					<div className="flex flex-col gap-2 mt-5">
						<p>{t('conversionType')}</p>
						<select
							disabled={stateCurrencyRate === ''}
							className={
								'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[250px]'
							}
							value={form.Type}
							onChange={handleTypeChange}
							placeholder={t('conversionType')}
						>
							<option value="Покупка">{'Покупка'}</option>
							<option value="Продажа">{'Продажа'}</option>
						</select>
					</div>

					{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ? (
						<div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-10">
							<div className="flex flex-col gap-3">
								<p>{t('selectCashOutgoing')}</p>
								<select
									disabled={stateCurrencyRate === ''}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[350px]'
									}
									value={form.Type === 'Покупка' ? uzsCash : dollarCash}
									onChange={(e) => {
										form.Type === 'Покупка'
											? setUzsCash(e.target.value)
											: setDollarCash(e.target.value)
									}}
									placeholder={t('cashOutgoing')}
								>
									<option key={100} value={''}></option>

									{form.Type === 'Покупка'
										? uzsAccounts.map((account) => (
												<option key={account.Code} value={account.Code}>
													{account.Name} ({account.Code}) -{' '}
													{account.AcctCurrency}
												</option>
											))
										: dollarAccounts.map((account) => (
												<option key={account.Code} value={account.Code}>
													{account.Name} ({account.Code}) -{' '}
													{account.AcctCurrency}
												</option>
											))}
								</select>
							</div>

							<div className="flex flex-col gap-3">
								<p>{t('selectCashIncoming')}</p>
								<select
									disabled={stateCurrencyRate === ''}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[350px]'
									}
									value={form.Type === 'Покупка' ? dollarCash : uzsCash}
									onChange={(e) => {
										form.Type === 'Покупка'
											? setDollarCash(e.target.value)
											: setUzsCash(e.target.value)
									}}
									placeholder={t('cashIncoming')}
								>
									<option key={100} value={''}></option>

									{form.Type === 'Покупка'
										? dollarAccounts.map((account) => (
												<option key={account.Code} value={account.Code}>
													{account.Name} ({account.Code}) -{' '}
													{account.AcctCurrency}
												</option>
											))
										: uzsAccounts.map((account) => (
												<option key={account.Code} value={account.Code}>
													{account.Name} ({account.Code}) -{' '}
													{account.AcctCurrency}
												</option>
											))}
								</select>
							</div>
						</div>
					) : (
						''
					)}
					<div className="flex flex-col gap-3 mt-10">
						<div className="flex flex-col gap-2 items-start">
							<p>{t('inputPriceCurrency')}</p>
							<input
								type="text"
								disabled={stateCurrencyRate === ''}
								placeholder={t('price')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[250px]'
								}
								value={form.CashSumUsd}
								onChange={handleSumChange}
							/>
						</div>
						<div className="flex flex-col gap-2 items-start">
							<p>{t('equivalientInSum')}</p>
							<input
								type="text"
								placeholder={t('otherCurrency')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-[250px]'
								}
								disabled
								value={
									new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})
										.format(form.CashSumUzs)
										.replace(',', '.') + ' UZS'
								}
							/>
						</div>
					</div>
				</div>
				<div className="flex items-center justify-center">
					<Button
						className="w-[130px] rounded-2xl bg-[#0A4D68] h-12 text-white disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300 disabled:border-gray-300"
						disabled={
							form.DocDate === '' ||
							form.Type === '' ||
							form.CashSumUzs === '' ||
							form.CashSumUsd === ''
						}
						onClick={() => {
							handleRefund(form)
						}}
						isLoading={btnLoading}
					>
						{t('create')}
					</Button>
				</div>
			</div>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
			<SuccessModal
				getRef={(r) => {
					succesModal.current = r
				}}
				onConfirm={() => {
					window.location.reload()
				}}
			/>
		</Layout>
	)
}

export default RefundS
