import React, { useEffect } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import DrawerMenuStyle from './DrawerMenuStyle'
import { useLocation, useNavigate } from 'react-router-dom'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const DrawerMenu = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation()

	const [isOpen, setIsOpen] = React.useState(false)
	const [activeParent, setActiveParent] = React.useState()
	const [activeMenu, setActiveMenu] = React.useState(location.pathname.slice(1))
	const { getMe } = useSelector((state) => state.main)
	const data =
		get(getMe, 'Department2.Name', '') === 'DokonManager'
			? [
					{
						title: t('dashboard'),
						navigate: 'dashboard',
					},
					{
						title: t('invoices'),
						key: 'allProducts',
						children: [
							{
								title: t('invoice'),
								navigate: 'cash-payment',
							},
							{
								title: t('installment-plan'),
								navigate: 'installment-plan',
							},
						],
					},
					{
						title: t('icloud'),
						navigate: 'icloud',
					},
					{
						title: t('whsMovement'),
						key: 'warehouseM',
						children: [
							{
								title: t('purchases'),
								navigate: 'purchases',
							},
							{
								title: t('inventory-transfer'),
								navigate: 'inventory-transfer',
							},

							{
								title: t('whsReports'),
								navigate: 'reports',
							},
						],
					},

					{
						title: t('clients'),
						key: 'clients',
						children: [
							{
								title: t('active-clients'),
								navigate: 'active-clients',
							},
							{
								title: t('inactive-clients'),
								navigate: 'inactive-clients',
							},
						],
					},

					{
						title: t('cash'),
						key: 'cash',
						children: [
							{
								title: t('outgoing-payment'),
								navigate: 'outgoing-payment',
							},
							{
								title: t('cash-flow-report'),
								navigate: 'cash-flow-report',
							},
							{
								title: t('dds-report'),
								navigate: 'dds-report',
							},
							{
								title: t('refund'),
								navigate: 'refund',
							},
						],
					},
					{
						title: t('purchase-returns'),
						key: 'returns',
						children: [
							{
								title: t('purchase-returns'),
								navigate: 'purchase-returns',
							},
							{
								title: t('discounts'),
								navigate: 'forgivved',
							},
						],
					},

					{
						title: t('logout'),
						navigate: 'login',
					},
				]
			: get(getMe, 'Department2.Name', '') === 'Sotuv'
				? [
						{
							title: t('recovery'),
							navigate: 'recovery',
						},
						{
							title: t('whsMovement'),
							key: 'Transfers',
							children: [
								{
									title: t('addProduct'),
									navigate: 'addProduct',
								},
								{
									title: t('inventory-transfer'),
									navigate: 'inventory-transfer',
								},
								{
									title: t('purchases'),
									navigate: 'purchases',
								},
								{
									title: t('whsReports'),
									navigate: 'reports',
								},
							],
						},
						{
							title: t('invoices'),
							key: 'allProducts',
							children: [
								{
									title: t('invoice'),
									navigate: 'cash-payment',
								},
								{
									title: t('installment-plan'),
									navigate: 'installment-plan',
								},
							],
						},
						{
							title: t('purchases'),
							navigate: 'purchases',
						},

						{
							title: t('logout'),
							navigate: 'login',
						},
					]
				: get(getMe, 'Department2.Name', '') === 'Undiruv'
					? [
							{
								title: t('invoice'),
								navigate: 'undiruv-installment-plan',
							},
							{
								title: t('recovery'),
								navigate: 'recovery',
							},

							{
								title: t('logout'),
								navigate: 'login',
							},
						]
					: get(getMe, 'Department2.Name', '') === 'Undiruvchi1'
						? [
								{
									title: t('recovery'),
									navigate: 'recovery',
								},
								{
									title: t('dollarCourse'),
									navigate: 'dollarCourse',
								},
								{
									title: t('client'),
									key: 'clients',
									children: [
										{
											title: t('active-clients'),
											navigate: 'active-clients',
										},
										{
											title: t('inactive-clients'),
											navigate: 'inactive-clients',
										},
									],
								},
								{
									title: t('sms'),
									navigate: 'sms',
								},
								{
									title: t('logout'),
									navigate: 'login',
								},
							]
						: get(getMe, 'Department2.Name', '') === 'Undiruv_kocha'
							? [
									{
										title: t('recovery'),
										navigate: 'recovery',
									},
									{
										title: t('dollarCourse'),
										navigate: 'dollarCourse',
									},
									{
										title: t('client'),
										key: 'clients',
										children: [
											{
												title: t('active-clients'),
												navigate: 'active-clients',
											},
											{
												title: t('inactive-clients'),
												navigate: 'inactive-clients',
											},
										],
									},
									{
										title: t('sms'),
										navigate: 'sms',
									},
									{
										title: t('logout'),
										navigate: 'login',
									},
								]
							: get(getMe, 'Department2.Name', '') === 'Scoring'
								? [
										{
											title: t('clients'),
											navigate: 'scoring-clients',
										},
										{
											title: t('client'),
											key: 'clients',
											children: [
												{
													title: t('active-clients'),
													navigate: 'active-clients',
												},
												{
													title: t('inactive-clients'),
													navigate: 'inactive-clients',
												},
											],
										},
										{
											title: t('logout'),
											navigate: 'login',
										},
									]
								: [
										{
											title: t('dashboard'),
											navigate: 'dashboard',
										},

										{
											title: t('invoices'),
											key: 'allProducts',
											children: [
												{
													title: t('invoice'),
													navigate: 'cash-payment',
												},
												{
													title: t('installment-plan'),
													navigate: 'installment-plan',
												},
											],
										},
										{
											title: t('icloud'),
											navigate: 'icloud',
										},
										{
											title: t('clients'),
											key: 'clients',
											children: [
												{
													title: t('active-clients'),
													navigate: 'active-clients',
												},
												{
													title: t('inactive-clients'),
													navigate: 'inactive-clients',
												},
											],
										},
										{
											title: t('collectors'),
											key: 'reportRecovery',
											children: [
												{
													title: t('recovery'),
													navigate: 'recovery',
												},
												{
													title: t('recoveryReports'),
													navigate: 'recoveryChart',
												},
												{
													title: t('sms'),
													navigate: 'sms',
												},
											],
										},

										{
											title: t('whsMovement'),
											key: 'Transfers',
											children: [
												{
													title: t('purchases'),
													navigate: 'purchases',
												},
												{
													title: t('inventory-transfer'),
													navigate: 'inventory-transfer',
												},
												{
													title: t('whsReports'),
													navigate: 'reports',
												},
												{
													title: t('addProduct'),
													navigate: 'addProduct',
												},
											],
										},

										{
											title: t('credit-calculator'),
											navigate: 'settings',
										},
										{
											title: t('cash'),
											key: 'cash',
											children: [
												{
													title: t('outgoing-payment'),
													navigate: 'outgoing-payment',
												},
												{
													title: t('cash-flow-report'),
													navigate: 'cash-flow-report',
												},
												{
													title: t('dds-report'),
													navigate: 'dds-report',
												},
												{
													title: t('dollarCourse'),
													navigate: 'dollarCourse',
												},
												{
													title: t('refund'),
													navigate: 'refund',
												},
											],
										},

										{
											title: t('purchase-returns'),
											key: 'returns',
											children: [
												{
													title: t('purchase-returns'),
													navigate: 'purchase-returns',
												},
												{
													title: t('discounts'),
													navigate: 'forgivved',
												},
											],
										},

										{
											title: t('logout'),
											navigate: 'login',
										},
									]

	const handleActive = (n) => {
		data.map((v) => {
			if (v.key === n) {
				setActiveParent(n)
				v.children.map((child) => {
					if (child.navigate === n) {
						setActiveMenu(n)
					}
				})
			} else if (v.navigate === n) {
				setActiveMenu(n)
			}
		})
	}

	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState)
	}

	const goToScreen = (n) => {
		if (n === 'login') {
			navigate(`/${n}`, { replace: true })
		} else {
			navigate(`/${n}`)
		}
	}

	function handleParent(key) {
		if (key === activeParent) {
			setActiveParent(null)
		} else {
			setActiveParent(key)
		}
	}

	useEffect(() => {
		data.map((v) => {
			if (v.children) {
				v.children.map((child) => {
					if (child.navigate === activeMenu) {
						setActiveParent(v.key)
					}
				})
			}
		})
	}, [])

	return (
		<DrawerMenuStyle>
			<button onClick={toggleDrawer} className="listBtn">
				<AiOutlineUnorderedList />
			</button>
			<Drawer open={isOpen} onClose={toggleDrawer} direction="left">
				<div className="drawer">
					<p className="menuTitle ">Меню</p>

					{data.map((v, i) => {
						if (v.children)
							return (
								<div key={i}>
									<button
										className={
											v.key === activeParent ? 'btnParentActive' : 'btn'
										}
										onClick={() => handleParent(v.key)}
									>
										{v.title} {activeParent !== v.key ? '>' : '<'}
									</button>
									{activeParent === v.key &&
										v.children.map((child, i) => (
											<button
												key={i}
												className={
													activeMenu === child.navigate ? 'btnActive' : 'btn'
												}
												onClick={() => {
													goToScreen(child.navigate)
													handleActive(child.navigate)
													handleActive(v.key)
												}}
											>
												{child.title}
											</button>
										))}
								</div>
							)
						else
							return (
								<button
									key={i}
									className={v.navigate === activeMenu ? 'btnActive' : 'btn'}
									onClick={() => {
										goToScreen(v.navigate)
										handleActive(v.navigate)
									}}
								>
									{v.title}
								</button>
							)
					})}
				</div>
			</Drawer>
		</DrawerMenuStyle>
	)
}

export default DrawerMenu
