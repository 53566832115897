import styled from 'styled-components'
import colors from '../../../assets/style/colors'
import BackImage from '../../../assets/images/back.jpg'

const CreateProductsStyle = styled.div`
	padding: 40px;

	// background-image: url(${BackImage});
	background-size: cover;
	background-position: center center;
	box-sizing: border-box;
	display: flex;
	justify-content: center;

	.container {
		display: flex;
		flex-wrap: wrap;
		box-shadow: 3px 3px 10px #cfcfcf;
		border-radius: 10px;
		background-color: #fffffff2;
		padding: 30px;
		align-items: flex-start;
		justify-content: space-between;
		.betweenCard {
			width: 30%;
			display: flex;
			flex-wrap: wrap;
			position: relative;
			.monthCard {
				display: flex;
				width: 100%;
			}
			.relative {
				width: 100%;
				box-sizing: border-box;
				padding-right: 20px;
				.none {
					display: none;
				}
				.absolutCard {
					position: absolute;
					bottom: 20px;
					left: 0;
					background-color: yellow;
					width: 100%;
					height: 200px;
					overflow: auto;
					.absoluteButton {
						background-color: transparent;
						border: 1px solid #d7d7d7;
						width: 100%;
					}
				}
				.input {
					border: 2px solid ${colors.gray};
					font-size: 15px;
					padding: 10px;
					border-radius: 5px;
					color: ${colors.mainColor};
					width: 100%;
					margin-bottom: 50px;
				}
			}

			.createUserBtn {
				padding: 11px 10px;
				margin-left: 5px;
				border-radius: 5px;
				background-color: ${colors.mainColor};
				font-size: 15px;
				color: #ffffff;
				border: none;
				width: 50px;
			}
			.input {
				border: 2px solid ${colors.gray};
				font-size: 15px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				width: 100%;
				margin-bottom: 50px;
			}
			.input2 {
				border: 2px solid ${colors.gray};
				font-size: 15px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				width: 100%;
				margin-bottom: 50px;
			}
			.inputDefault {
				border: 2px solid ${colors.gray};
				font-size: 15px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				width: 100%;
				margin-bottom: 50px;
				background-color: #ececec;
			}
			.table {
				border-collapse: collapse;
				margin-top: 20px;
				background-color: #ffffff;
				width: 100%;
				td,
				th {
					border: 1px solid ${colors.gray};
					padding: 5px;
					text-align: center;
				}
				.mainTd:hover {
					background-color: #a3fea6;
					cursor: pointer;
				}
				.inputStartPrice {
					background-color: #ffffff;
					border: 1px solid #c4c4c4;
					outline: none;
					width: 50px;
				}
			}
			.center {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
		}
		.card2 {
			width: 50%;
			display: flex;
			justify-content: center;
			.input {
				border: 2px solid ${colors.gray};
				font-size: 15px;
				padding: 10px;
				border-radius: 5px;
				color: ${colors.mainColor};
				width: 60%;
			}
		}
		.bottomCard {
			display: flex;
			width: 100%;
			.cancelBtn {
				background-color: red;
				margin: 50px;
				padding: 100px;
			}
		}
	}
	.debt {
		color: red;
	}
	.betweenCard2 {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
`
export default CreateProductsStyle
