import styled from 'styled-components'
import colors from '../../../assets/style/colors'

const Refund = styled.div`
	width: 900px;
	.card {
		background-color: #00000000;
		padding: 30px;
		border-radius: 5px;
		text-align: center;
		width: 400px;
		.input {
			border: 2px solid ${colors.gray};
			font-size: 15px;
			padding: 10px;
			border-radius: 5px;
			color: ${colors.mainColor};
			width: 90%;
			margin-bottom: 15px;
		}
	}
	.centerCard {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}
	.btnY {
		padding: 10px 30px;
		border-radius: 5px;
		transition: 0.2s;
		font-weight: 600;
		font-size: 17px;
	}

	.btnN {
		padding: 10px 30px;
		border-radius: 5px;
		border: 3px solid red;
		color: red;
		transition: 0.2s;
		font-weight: 600;
		font-size: 17px;
	}
	.btnN:hover {
		background-color: red;
		color: white;
	}
	.radioBtnCard {
		width: 150px;
		text-align: left;
		margin: auto;
	}
	@media (max-width: 640px) {
		width: 100%;
		.card {
			width: 100%;
			padding: 10px;
		}

		.centerCard,
		.radioBtnCard,
		.radioBtnCard2 {
			flex-direction: column; /* Stack elements vertically */
			align-items: center;
		}

		.btnY,
		.btnN {
			width: 100%; /* Make buttons full-width */
			font-size: 15px;
			padding: 8px 20px;
		}

		.halfCard {
			flex-direction: column; /* Stack cards vertically */
			.insideMiniCard {
				.input {
					width: 100%; /* Make input full-width */
				}
			}
		}
	}
`
export default Refund
