import React, { useEffect, useRef, useState } from 'react'
import AllProductsStyle from './AllProducts'
import Button from '../../components/Button'
import { useNavigate, useLocation } from 'react-router-dom'
import api, { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { roundCurrency } from '../../utils'
import { isAxiosError } from 'axios'
import _ from 'lodash'
import { cashPayment } from '../../store/slices'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import customMainApi from '../../api/index'
import { Empty, DatePicker, Button as AntButton } from 'antd'
import { useTranslation } from 'react-i18next'

const { RangePicker } = DatePicker
const handleBusinessPartner = _.debounce((searchFunc, value) => {
	searchFunc(value)
}, 1000)

const UsersInvoices = () => {
	const location = useLocation()
	const { t } = useTranslation()

	dayjs.extend(advancedFormat)
	dayjs.extend(customParseFormat)
	const query = new URLSearchParams(location.search)
	const CardCode = query.get('CardCode')

	const {
		setClient: setClientStore,
		setImei: setImeiStore,
		setCardCode: setCardCodeStore,
	} = cashPayment.actions
	const {
		client: clientStore,
		imei: imeiStore,
		cardCode: cardCodeStore,
	} = useSelector((state) => state.cashPayment)
	const dispatch = useDispatch()
	const dateFormat = 'YYYY-MM-DD'
	const [dateStart, setDateStart] = useState(
		dayjs().startOf('year').format(dateFormat),
	)
	const [dateEnd, setDateEnd] = useState(dayjs().format(dateFormat))

	const navigation = useNavigate()
	const errorRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [client, setClient] = useState(clientStore)
	const [imeiClient, setImeiClient] = useState(imeiStore)
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(0)
	const [userFields, setUserFields] = useState([])
	const [page, setPage] = useState(0)
	const [maxPage, setMaxPage] = useState(20)

	const search = () => {
		setIsLoading(true)
		// `Invoices?$filter=CardCode eq '${CardCode}' and Cancelled eq 'tNO'&$select=DocEntry,DocNum,CardCode,CardName,DocDate,DocTotal,PaidToDate,DocumentLines,SalesPersonCode`,

		customFuncsApi
			.get('XsSql/getInvoices', {
				params: {
					// type: 'oneinst',
					$skip: page * 10,
					IntrSerial: imeiClient || undefined,
					CardCode: CardCode,
					// itemCode: product || undefined,
					date1: dateStart + 'T22:00:00.000Z',
					date2: dateEnd + 'T22:00:00.000Z',
				},
				headers: {
					Prefer: `odata.maxpagesize=${maxPage}`,
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setData(resData)
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setIsLoading(false)
			})
	}

	useEffect(() => {
		userFieldsFn()
	}, [])
	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM' ",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		if (!client) {
			dispatch(setClientStore(client))
		}
	}, [client])

	useEffect(() => {
		search()
	}, [page, maxPage])

	const viewItem = (v) => {
		const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(v))}&DocEntry=${v.DocEntry}&notShow=${Boolean(true)}&CardCode=${CardCode}`
		window.open(url, '_blank')
	}

	const newDatas = () => {
		if ((data.length = maxPage)) {
			setPage((prevState) => prevState + Number(String(maxPage)[0]))
			setCurrentPage((prevState) => prevState + 1)
		} else {
			alert(t('noOtherInfo'))
		}
	}

	const oldData = () => {
		if (page === 0) {
			alert(t('noOtherInfo'))
		} else {
			setPage((prevState) => prevState - Number(String(maxPage)[0]))
			setCurrentPage((prevState) => prevState - 1)
		}
	}

	const handleSearch = () => {
		search()
	}
	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			const start = dates[0]
			const end = dates[1]

			setDateStart(start ? start.format('YYYY-MM-DD') : null)
			setDateEnd(end ? end.format('YYYY-MM-DD') : null)
		} else {
			console.log('Clear')
		}
	}

	return (
		<Layout>
			<AllProductsStyle>
				<div className="container">
					<p className="productTitle">{t('historyOfInvoices')}</p>
					<div className="flex flex-col sm:flex-row  justify-between items-center w-full">
						<div className="flex flex-col sm:flex-row items-center gap-10 mt-10 w-full">
							<input
								className={
									'border-[1px] border-[#d9d9d9"] p-2 rounded-md w-full  sm:w-auto '
								}
								type="text"
								placeholder={t('imei')}
								defaultValue={imeiClient}
								onChange={(v) => {
									setImeiClient(v.target.value)
									dispatch(setImeiStore(v.target.value))
								}}
							/>
							<div className="flex flex-col w-full">
								<RangePicker
									value={[
										dateStart ? dayjs(dateStart, dateFormat) : null,
										dateEnd ? dayjs(dateEnd, dateFormat) : null,
									]}
									format={dateFormat}
									onChange={onRangeChange}
									className='border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  sm:w-[300px] '
								/>
							</div>

							<AntButton
								onClick={() => handleSearch()}
								className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
								loading={isLoading}
							>
								{t('search')}
							</AntButton>
						</div>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto mb-8 mt-10">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												{t('clientCode')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('clientName')}
											</th>

											<th scope="col" className="px-6 py-4">
												{t('imei')}
											</th>

											<th scope="col" className="px-6 py-4">
												{t('product')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('page')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('icloud')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('totalPrice')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('paid')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('remainder')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('dateOfSale')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.length > 0 ? (
											data.map((v, i) => {
												const color =
													get(v, 'U_Color', '') !== ''
														? userFields
																?.find((item) => item.Name === 'Color')
																?.ValidValuesMD.find(
																	(value) =>
																		get(v, 'U_Color', '') === value.Value,
																)?.Description || ''
														: ''

												const memory =
													get(v, 'U_Memory', '') !== ''
														? userFields
																?.find((item) => item.Name === 'Memory')
																?.ValidValuesMD.find(
																	(value) =>
																		get(v, 'U_Memory', '') === value.Value,
																)?.Description || ''
														: ''

												const condition =
													get(v, 'U_Condition', '') !== ''
														? userFields
																?.find((item) => item.Name === 'Condition')
																?.ValidValuesMD.find(
																	(value) =>
																		get(v, 'U_Condition', '') === value.Value,
																)?.Description || ''
														: ''

												return (
													<tr
														key={i}
														onClick={() => viewItem(v)}
														className="bg-white border-b  hover:bg-gray-50 "
													>
														<td
															scope="row"
															className="px-6 py-4 font-medium text-gray-900 "
														>
															{get(v, 'CardCode', '-')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'CardName', '-')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'IntrSerial', '-')}
														</td>
														<td className="px-6 py-4">
															{get(v, 'Dscription', '')} {color} {memory}{' '}
															{condition}
														</td>
														<td className="px-6 py-4">
															{get(v, 'U_DaftarBet', '-')}
														</td>{' '}
														<td className="px-6 py-4">
															{get(v, 'U_Security', '-')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(roundCurrency(get(v, 'DocTotal', '-')))
																.replace(',', '.')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(get(v, 'PaidToDate', '-'))
																.replace(',', '.')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(
																	get(v, 'DocTotal', '-') -
																		get(v, 'PaidToDate', '-'),
																)
																.replace(',', '.')}
														</td>
														<td className="px-6 py-4">
															{moment(get(v, 'DocDate', '-')).format(
																'DD-MM-YYYY',
															)}
														</td>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex items-center gap-2 ">
									<Button
										disabled={page === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</Button>
									<p>{currentPage + 1}</p>
									<Button
										disabled={data.length < maxPage}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>

								<select
									value={maxPage}
									className="w-[50px] rounded-xl flex text-center"
									onChange={(e) => {
										setMaxPage(e.target.value)
										setPage(0)
										setCurrentPage(0)
									}}
								>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="30">30</option>
									<option value="50">50</option>
									<option value="80">80</option>
								</select>
							</div>
						</>
					)}
				</div>
			</AllProductsStyle>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default UsersInvoices
