import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import uzb from './locales/uzb.json'
import ru from './locales/ru.json'
const storedLanguage = localStorage.getItem('language')
const language = storedLanguage || 'ru'
const resources = {
	uzb: {
		translation: uzb,
	},
	ru: {
		translation: ru,
	},
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: language,
		debug: true,
		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
