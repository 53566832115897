import React, { useState } from 'react'
import DDSReport from './DDSReport'
import Layout from '../../../components/Layout'
import VendorPayments from './vendorPayments/index'
import IncomingPayments from './IncomingPayments/index'
import { useTranslation } from 'react-i18next';

const DDSReportPage = () => {
	const [activeTab, setActiveTab] = useState('tab1')
	const { t } = useTranslation()

	const renderContent = () => {
		switch (activeTab) {
			case 'tab1':
				return <IncomingPayments />
			case 'tab2':
				return <VendorPayments />

			default:
				return null
		}
	}

	return (
		<Layout>
			<DDSReport>
				<div className="container">
					<div className="m-5">
						<p className="productTitle">{t("dds-report")}</p>

						<div style={{ marginBottom: 16 }} className="mt-10">
							<button
								className={`${
									activeTab === 'tab1'
										? 'text-blue-900 underline underline-offset-8'
										: ''
								} font-semibold `}
								onClick={() => setActiveTab('tab1')}
							>
								{t("incoming-payment")}
							</button>
							<button
								className={`${
									activeTab === 'tab2'
										? 'text-blue-900 underline underline-offset-8'
										: ''
								} font-semibold ml-3`}
								onClick={() => setActiveTab('tab2')}
							>
								{t("outgoing-payment")}
							</button>
						</div>
					</div>
					<div className="mt-10">{renderContent()}</div>
				</div>
			</DDSReport>
		</Layout>
	)
}

export default DDSReportPage
