import React, { useEffect, useRef, useState } from 'react'
import OutgoingPayment from './OutgoingPayment'
import Button from '../../../components/Button'
import api, { customFuncsApi } from '../../../api'
import _, { get } from 'lodash'
import Layout from '../../../components/Layout'
import { ErrorModal } from '../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'

const OutgoingPaymentPage = () => {
	const errorRef = useRef()
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)
	const [startDate, setStartDate] = useState(
		new Date().toISOString().split('T')[0],
	)
	const [pagination, setPagination] = useState({
		page: 0,
	})
	const [data, setData] = useState({
		data: [],
	})
	const search = (value) => {
		setIsLoading(true)
		const formattedName = value?.cardName.includes("'")
			? value?.cardName.replace(/'/g, "''")
			: value?.cardName
		api
			.get(
				`/VendorPayments?%24select=DocEntry,DocNum,DocType,CardCode,CardName,CashAccount,DocCurrency,CashSum,ControlAccount,DocDate,CashSumFC&$filter=${value?.cardCode !== undefined ? `CardCode eq '${value?.cardCode}' and ` : ''}${formattedName !== undefined ? `CardName eq '${formattedName}' and ` : ''}DocDate ge '${startDate + ' 00:00:00.000Z'}'&$skip=${pagination.page * 20}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=20',
					},
				},
			)
			.then((res) => {
				const resData = JSON.parse(res.data).value
				setData({
					data: [...resData],
				})
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search()
	}, [startDate, pagination])

	const newDatas = () => {
		if (data.data.length < 20) {
			alert(t('noInfo'))
		} else {
			setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
		}
	}

	const oldData = () => {
		if (pagination.page > 0) {
			setPagination((prev) => ({ ...prev, page: prev.page - 1 }))
		} else {
			alert(t('noInfo'))
		}
	}

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value)
	}

	const handleCardCodeChange = _.debounce((e) => {
		search({ cardCode: e.target.value })
	}, 1000)

	const handleCardNameChange = _.debounce((e) => {
		search({ cardName: e.target.value })
	}, 1000)

	return (
		<Layout>
			<OutgoingPayment>
				<div className="container">
					<p className="productTitle">{t('outgoing-payment')}</p>
					<div className="flex items-center gap-5 sm:gap-10 flex-col sm:flex-row mt-8">
						<div className="w-full">
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('clientCode')}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full sm:w-auto'
								}
								type="text"
								onChange={handleCardCodeChange}
							/>
						</div>
						<div className="w-full">
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('customer')}
							</p>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full sm:w-auto'
								}
								type="text"
								onChange={handleCardNameChange}
							/>
						</div>
						<div className="w-full">
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('startDate')}
							</p>
							<input
								type="date"
								className={
									'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full sm:w-auto'
								}
								value={startDate}
								onChange={handleStartDateChange}
							/>
						</div>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto mb-8 mt-10">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												{t('docNum')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('customer')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('fromAcc')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('currency')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('price')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('toAcc')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('date')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{get(v, 'DocNum', '')}
													</td>
													<td className="px-6 py-4 whitespace-nowrap">
														{get(v, 'CardName', '')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'CashAccount', '')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'DocCurrency', '')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'DocCurrency', '') !== 'UZS'
															? new Intl.NumberFormat('fr-FR', {
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																})
																	.format(get(v, 'CashSum', ''))
																	.replace(',', '.')
															: new Intl.NumberFormat('fr-FR', {
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																})
																	.format(get(v, 'CashSumFC', ''))
																	.replace(',', '.')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'CardCode', '')}
													</td>
													<td className="px-6 py-4">
														{moment(get(v, 'DocDate', '')).format('DD-MM-YYYY')}
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description={t('noInfo')}
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="flex  justify-between  flex-col sm:flex-row gap-5">
								<div className="flex items-center gap-2">
									<button
										className="bg-[#0A4D68] text-white w-[35px] h-[35px] rounded-2xl"
										onClick={oldData}
									>
										{'<'}
									</button>
									<p>{pagination.page + 1}</p>
									<button
										className="bg-[#0A4D68] text-white w-[35px] h-[35px] rounded-2xl"
										onClick={newDatas}
									>
										{'>'}
									</button>
								</div>
								<button
									className="bg-[#0A4D68] text-white p-2 w-full sm:w-auto h-10 rounded-lg"
									onClick={() => window.open('/pay-supplier', '_blank')}
								>
									{t('customerPayment')}
								</button>
								<button
									className="bg-[#0A4D68] text-white p-2 w-full sm:w-auto h-10 rounded-lg"
									onClick={() => window.open('/constant-consumption', '_blank')}
								>
									{t('fixedCosts')}
								</button>
							</div>
						</>
					)}
				</div>
			</OutgoingPayment>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default OutgoingPaymentPage
