import React, { useState, useEffect } from 'react'
import DrawerMenu from '../DrawerMenu'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { FaSignOutAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'

const Layout = ({ children }) => {
	const { getMe } = useSelector((state) => state.main)
	const { t, i18n } = useTranslation()

	const navigate = useNavigate()
	const [clicked, setClicked] = useState(false)

	useEffect(() => {
		const storedLanguage = localStorage.getItem('language')
		if (storedLanguage) {
			i18n.changeLanguage(storedLanguage)
		}
	}, [i18n])
	const handleClick = () => {
		navigate('/login')
	}
	const handleLanguageChange = (value) => {
		i18n.changeLanguage(value)
		localStorage.setItem('language', value)
	}
	return (
		<div className="">
			<DrawerMenu />

			<div
				className="h-screen w-full overflow-y-auto"
				onClick={() => {
					if (clicked) setClicked(false)
				}}
			>
				<div className="relative flex h-[80px] w-full items-center justify-end gap-4 bg-[#FFFFFF] pr-10 drop-shadow-xl">
					<Select
						className=" w-32 ml-5"
						defaultValue={i18n.language}
						onChange={handleLanguageChange}
						style={{ width: 120 }}
					>
						{/* <Select.Option value="en">
							<span className="flex items-center">
								<Flag code="GB" className="mr-2" style={{ width: 20 }} />
								EN
							</span>
						</Select.Option> */}
						<Select.Option value="ru">
							<span className="flex items-center">
								<Flag code="RU" className="mr-2" style={{ width: 20 }} />
								RU
							</span>
						</Select.Option>
						<Select.Option value="uzb">
							<span className="flex items-center">
								<Flag code="UZ" className="mr-2" style={{ width: 20 }} />
								UZB
							</span>
						</Select.Option>
					</Select>
					<div
						className="flex items-center gap-3"
						onClick={(event) => {
							event.stopPropagation() // Prevents the click event from reaching the parent div
							setClicked(!clicked)
						}}
					>
						<div className="ml-10 flex h-8 w-8 items-center justify-center rounded-full bg-[#0085FF1A] text-[#0085FF]">
							{get(getMe, 'FirstName', '')[0]}
						</div>
						<h1 className="font-inter text-base font-medium">
							{get(getMe, 'FirstName', '') + ' ' + get(getMe, 'LastName', '')}
						</h1>
						{clicked && (
							<div className="absolute right-3 top-[70px] mt-4 w-full max-w-60 rounded bg-[#FFFFFF] p-4 drop-shadow-xl">
								<div className="flex flex-col gap-2">
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('dollarCash')}</span>
										<span>{get(getMe, 'U_CashAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('dollarCard')}</span>
										<span>{get(getMe, 'U_CardUsdAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('card')}</span>
										<span>{get(getMe, 'U_CardAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('SumCash')}</span>
										<span>{get(getMe, 'U_CashUzsAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('terminal')}</span>
										<span>{get(getMe, 'U_TerminalAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('transferCash')}</span>
										<span>{get(getMe, 'U_TransAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('whs')}</span>
										<span>{get(getMe, 'U_Warehouse', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">{t('jobTitle')}</span>
										<span>{get(getMe, 'JobTitle', '')}</span>
									</div>
								</div>
								<button
									className="mt-4 flex w-full items-center justify-center gap-2 rounded bg-red-500 p-2 text-white"
									onClick={handleClick}
								>
									{t('logout')} <FaSignOutAlt />
								</button>
							</div>
						)}
					</div>
				</div>
				{children}
			</div>
		</div>
	)
}

export default Layout
