import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		width: '70%',
		maxHeight: '100vh', // To make modal content scrollable if it exceeds viewport height
		// overflowY: 'auto',
	},
	overlay: {
		background: '#00000099',
	},
}

const ImeiModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [data, setData] = useState([])

	useEffect(() => {
		const ref = {
			open: (data) => {
				// // setDocEntry(data.DocEntry)
				// getOneStockTransfers(data)
				setIsOpenModal(true)
				setData(data)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<div className="card">
				<h1 className="text-center text-lg font-bold">{'IMEI'}</h1>

				<div className=" w-full flex flex-wrap mb-8 mt-2">
					{data ? data : 'Нет IMEI'}
				</div>
			</div>
		</Modal>
	)
}

export default ImeiModal
