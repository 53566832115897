import React, { useEffect, useRef, useState } from 'react'
import AllProductsStyle from '../CashPayment/AllProducts'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { roundCurrency } from '../../utils'
import { isAxiosError } from 'axios'
import _ from 'lodash'
import { cashPayment } from '../../store/slices'
import { useDispatch, useSelector } from 'react-redux'
import customMainApi from '../../api/index'
import InventoryTransfersModal from '../../components/Modal/InventoryTansfersModal'
import { useTranslation } from 'react-i18next'
import { Button as AntButton } from 'antd'
const AllProducts = () => {
	const {
		setClient: setClientStore,
		setImei: setImeiStore,
		setCardCode: setCardCodeStore,
	} = cashPayment.actions
	const {
		client: clientStore,
		imei: imeiStore,
		cardCode: cardCodeStore,
	} = useSelector((state) => state.cashPayment)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const navigation = useNavigate()
	const errorRef = useRef()
	const infoModalRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [clientLoading, setClientLoading] = useState(false)
	const [warehouses, setWarehouses] = React.useState([])
	const [data, setData] = useState([])
	const [currentPage, setCurrentPage] = useState(0)
	const [clientsData, setClientsData] = useState([])
	const [userFields, setUserFields] = useState([])

	const search = () => {
		setIsLoading(true)
		customMainApi
			.get(`StockTransfers`, {
				params: {
					$skip: currentPage,
					$orderby: 'DocNum desc',

					// type: 'oneinst',
					// $skip: currentPage,
					// IntrSerial: imeiClient || undefined,
					// CardCode: client && clientCode ? clientCode : undefined,
					// itemCode: product || undefined,
				},
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			})
			.then((res) => {
				const resData = JSON.parse(res.data)
				setData(resData.value)
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err)) errorRef.current?.open(get(err, 'message', ''))
				setIsLoading(false)
			})
	}

	useEffect(() => {
		userFieldsFn()
		getWarehouses()
	}, [])
	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM' ",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const getWarehouses = () => {
		customMainApi
			.get('Warehouses?$select=WarehouseCode,WarehouseName')
			.then((res) => {
				setWarehouses(JSON.parse(res.data).value)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
	}

	const generatePDF = (docEntry, e) => {
		e.stopPropagation()
		// setIsLoading(true)
		customFuncsApi
			.get(`makeInventoryTransferPdf/${docEntry}`, {
				headers: {
					'Content-Type': 'application/pdf', // Set the correct MIME type for PDF
				},
				responseType: 'blob', // Ensure the response is treated as a blob
			})
			.then((res) => {
				const url = window.URL.createObjectURL(
					new Blob([res.data], { type: 'application/pdf' }), // Correct the blob type for PDF
				)
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'InventoryTransfer.pdf') // Change file name and extension to .pdf
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
			.catch((err) => {
				if (isAxiosError(err)) {
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				}
				// setIsLoading(false)
			})
	}

	useEffect(() => {
		search()
	}, [currentPage])

	const newDatas = () => {
		if (data.length >= 10) {
			setCurrentPage(currentPage + 10)
		} else {
			alert(t('noOtherInfo'))
		}
	}

	const oldData = () => {
		if (data.oldPage < 0) {
			alert(t('noOtherInfo'))
		} else {
			setCurrentPage(currentPage - 10)
		}
	}

	return (
		<Layout>
			<AllProductsStyle>
				<div className="container">
					<p className="productTitle">{t('inventory-transfer')}</p>

					<div className="flex items-center justify-center sm:justify-between w-full mt-5">
						<div className="flex gap-5 flex-col md:flex-row max-w-md">
							<div className="flex gap-2 items-center">
								<div className="size-6 rounded-full bg-green-400 font-medium"></div>
								<span>Открыто</span>
							</div>
							<div className="flex gap-2 items-center">
								<div className="size-6 rounded-full bg-red-800 font-medium"></div>
								<span> Закрыто</span>
							</div>
						</div>
						<AntButton
							className={'bg-[#0A4D68] h-10 text-white w-full sm:w-auto'}
							onClick={() =>
								window.open('/create-inventory-transfers', '_blank')
							}
						>
							{t('add')}
						</AntButton>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto mb-8 mt-5">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												{t('status')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('docNum')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('fromWhs')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('toWhs')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('inventoryDate')}
											</th>
											<th scope="col" className="px-6 py-4">
												{t('downloadPDF')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data.map((v, i) => {
											const fromWarehouse = warehouses.find(
												(i) => i.WarehouseCode === get(v, 'FromWarehouse', '-'),
											)
											const toWarehouse = warehouses.find(
												(i) => i.WarehouseCode === get(v, 'ToWarehouse', '-'),
											)

											return (
												<tr
													key={i}
													onClick={() =>
														infoModalRef.current?.open(get(v, 'DocEntry', '-'))
													}
													className={`bg-white  border-b hover:bg-gray-50`}
												>
													<td className="px-6 py-4 font-medium ">
														<div
															className={
																get(v, 'DocumentStatus', 0) === 'bost_Open'
																	? 'size-7 rounded-full bg-green-400 '
																	: 'size-7 rounded-full bg-red-800 '
															}
														></div>
													</td>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900"
													>
														{get(v, 'DocNum', '-')}
													</td>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900"
													>
														{fromWarehouse ? fromWarehouse.WarehouseName : ''}
													</td>
													<td className="px-6 py-4">
														{toWarehouse ? toWarehouse.WarehouseName : ''}
													</td>
													<td className="px-6 py-4">
														{moment(get(v, 'DocDate', '-')).format(
															'YYYY-MM-DD',
														)}
													</td>
													<td className="px-6 py-4">
														<Button
															className={'btn'}
															onClick={(e) => {
																generatePDF(get(v, 'DocEntry', ''), e)
															}}
														>
															{t('downloadPDF')}
														</Button>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex items-center gap-2 ">
									<Button
										disabled={currentPage === 0}
										className={'btn'}
										onClick={oldData}
									>
										{'<'}
									</Button>
									<p>{currentPage / 10 + 1 || 1}</p>
									<Button
										disabled={data.length < 10}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</AllProductsStyle>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
				<InventoryTransfersModal
					getRef={(ref) => (infoModalRef.current = ref)}
					onUpdated={() => search()}
				/>
			</>
		</Layout>
	)
}

export default AllProducts
