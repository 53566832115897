import React, { useState } from 'react'
import Layout from '../../components/Layout'

import { Space } from 'antd'
import Tab1 from './Tab1/index'
import Tab2 from './Tab2/index'
import { useTranslation } from 'react-i18next'

const Dashboard = () => {
	const [activeTab, setActiveTab] = useState('tab1')
	const { t } = useTranslation()

	const renderContent = () => {
		switch (activeTab) {
			case 'tab1':
				return <Tab1 />
			case 'tab2':
				return <Tab2 />

			default:
				return null
		}
	}

	return (
		<Layout>
			<div className="m-5">
				<h1 className="text-2xl font-bold">{t('dashboard')}</h1>
				<Space style={{ marginBottom: 16 }} className="mt-10">
					<button
						className={`${
							activeTab === 'tab1'
								? 'text-blue-900 underline underline-offset-8'
								: ''
						} font-semibold `}
						onClick={() => setActiveTab('tab1')}
					>
						{t('dashboard')}
					</button>
					<button
						className={`${
							activeTab === 'tab2'
								? 'text-blue-900 underline underline-offset-8'
								: ''
						} font-semibold `}
						onClick={() => setActiveTab('tab2')}
					>
						{t('reports')}
					</button>
				</Space>
				<div className="mt-3">{renderContent()}</div>
			</div>
		</Layout>
	)
}

export default Dashboard
