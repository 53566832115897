import React, { useRef, useState } from 'react'
import DollarCourseStyle from './DollarCourseStyle'
import Button from '../../components/Button'
import api, { customFuncsApi } from '../../api'
import Layout from '../../components/Layout'
import { ErrorModal, SuccessModal } from '../../components/Modal'
import { get } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const DollarCourse = () => {
	const formattedDate = moment().format('YYYY-MM-DD')
	const { t } = useTranslation()

	const successRef = useRef()
	const errorRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [dollar, setDollar] = useState('')
	const [date1, setDate1] = useState(formattedDate)
	const [date2, setDate2] = useState(formattedDate)

	const createDollarCourse = () => {
		setIsLoading(true)
		customFuncsApi
			.post(`setCurrRateForDates`, {
				rate: dollar, // kurs
				date1: date1, // sana
				date2: date2, // sana
			})
			.then(() => {
				setIsLoading(false)
				successRef.current?.open('Курс доллара успешно создан')
			})
			.catch((err) => {
				setIsLoading(false)
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	return (
		<Layout>
			<DollarCourseStyle>
				<div className="container">
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-end gap-4">
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>Доллар</p>
							<input
								type="number"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={dollar}
								onInput={(v) => setDollar(v.target.value)}
							/>
						</div>

						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('periodFrom')}
							</p>
							<input
								type="date"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={date1}
								onInput={(v) => setDate1(v.target.value)}
							/>
						</div>
						<div>
							<p className={'font-medium text-zinc-600 text-sm mb-1'}>
								{t('periodTo')}
							</p>
							<input
								type="date"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={date2}
								onInput={(v) => setDate2(v.target.value)}
							/>
						</div>

						<Button
							isLoading={isLoading}
							onClick={createDollarCourse}
							btnStyle={{ width: 150 }}
							hoverBtnStyle={{ width: 150 }}
						>
							{t('add2')}
						</Button>
					</div>
				</div>
			</DollarCourseStyle>
			<SuccessModal getRef={(r) => (successRef.current = r)} />
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
		</Layout>
	)
}

export default DollarCourse
