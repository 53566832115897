import styled from 'styled-components'
import colors from '../../assets/style/colors'

export const Settings = styled.div`
	height: 100vh;

	button:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}



	.loading {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.24);
		z-index: 1;
	}

	.loader {
		margin-top: 150px;
		margin-left: 50%;
		transform: translateX(-50%);
	}


	.modal {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.24);
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;

		h2 {
			margin-bottom: 30px;
		}

		form {
			display: flex;
			flex-direction: column;
			margin-top: 20px;

			.field {
				display: flex;
				flex-direction: column;
			}

			label {
				margin-bottom: 10px;
				margin-right: 5px;
			}

			input {
				padding: 10px;
				margin-bottom: 20px;
			}

			button {
				margin-top: 20px;
				padding: 10px 20px;
				background-color: ${colors.mainColor};
				color: #fff;
				border: none;
				border-radius: 5px;
				cursor: pointer;
			}
		}
	}

	.modal-self {
		position: relative;
		width: 50%;
		background-color: #fff;
		padding: 20px;
		border-radius: 5px;
	}

	.exit {
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;
		background-color: #ef2020;
		color: #fff;
		padding: 5px 10px;
	}

	.toggle {
		display: flex;
		justify-content: center;
		margin: 20px 0;

		div {
			cursor: pointer;
			padding: 10px 20px;
			border: 1px solid #000;
			border-radius: 5px;
			margin: 0 10px;
		}

		.toggle-active {
			background-color: ${colors.mainColor};
			color: #fff;
			border: none;
		}
	}

	.create-btn {
		background-color: ${colors.mainColor};
		color: #fff;
		padding: 10px 20px;
		border: none;
		border-radius: 5px;
		cursor: pointer;
	}

	table {
		.edit-btn {
			font-size: 17px;
			background-color: ${colors.mainColor2};
			color: #fff;
			padding: 5px 10px;
			border: none;
			border-radius: 5px;
			cursor: pointer;
		}

		.delete-btn {
			margin-left: 20px;
			font-size: 17px;
			background-color: #ea2828;
			color: #fff;
			padding: 5px 10px;
			border: none;
			border-radius: 5px;
			cursor: pointer;
		}

		.action-box {
			width: 45%;
			text-align: center;
		}
	}

	/* Responsive styles for max-width 640px */
	@media (max-width: 640px) {
		.title-box {
			padding: 20px 5%;
		}

		.loader {
			margin-top: 100px;
		}

		.container,
		.modal-self {
			margin: 20px 5%;
			width: 90%;
		}

		.modal-self {
			width: 100%;
		}

		.exit {
			padding: 5px 8px;
			right: 5px;
			top: 5px;
		}

		.toggle div {
			padding: 8px 15px;
			font-size: 14px;
		}

		.create-btn,
		.edit-btn,
		.delete-btn {
			padding: 8px 16px;
			font-size: 14px;
		}

		table {
			margin-top: 10px;
			font-size: 14px;
		}

		.action-box {
			width: 100%;
		}
	}
`
