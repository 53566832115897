import React, { useEffect, useRef, useState } from 'react'
import CreateProductsStyle from './CreateProductsStyle'
import Button from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi, customPhotoUploadApi } from '../../../api'
import { get } from 'lodash'
import { ClipLoader } from 'react-spinners'

import { BsSearch } from 'react-icons/bs'
import {
	ErrorModal,
	SuccessModal,
	WarningModal,
} from '../../../components/Modal'
import CreateUserModal from '../../../components/Modal/CashCreateUser'
import { useSelector } from 'react-redux'
import ChangeDate from './ChangeDate'
import Layout from '../../../components/Layout'
import moment from 'moment'
import customMainApi from '../../../api/index'
import { message, Input, Button as AntButton } from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const CreateProducts = () => {
	const { getMe } = useSelector((state) => state.main)
	const { t } = useTranslation()

	const navigate = useNavigate()

	const changeDateRef = useRef()
	const errorRef = useRef()
	const infoNotEndedRef = useRef()
	const successModalRef = useRef()
	const succesCreateUserRef = useRef()
	const createUserRef = useRef()

	const [customerData, setCustomerData] = useState([])
	const [item, setItem] = useState({ name: '', code: '' })
	const [U_DaftarBet, setU_DaftarBet] = useState('')
	const [productNameData, setProductNameData] = useState([])
	const [seriaNumberData, setSeriaNumberData] = useState([])
	const [tableData, setTableData] = useState([])
	const [simpleMonthData, setsimpleMonthData] = useState([])
	const [vipMonthData, setVipMonthData] = useState([])

	const [price, setPrice] = useState('')
	const [color, setColor] = useState('')
	const [month, setMonth] = useState('')
	const [monthB2B, setMonthB2B] = useState('')
	const [customer, setCustomer] = useState('')
	const [condition, setCondition] = useState('')
	const [productName, setProductName] = useState('')
	const [seriaNumber, setSeriaNumber] = useState('')
	const [currentPrice, setCurrentPrice] = useState('')
	const [userCardCode, setUserCardCode] = useState('')
	const [seriaNumberMain, setSeriaNumberMain] = useState('')
	const [systemSerialNumber, setSystemSerialNumber] = useState('')
	const [minusedMoney, setMinusedMoney] = useState(0)
	const [productCode, setProductCode] = useState(0)
	const [dateIndex, setDateIndex] = useState(0)
	const [viewData, setViewData] = useState({})
	const [isCash, setIsCash] = useState(true)
	const [isCalculated, setIsCalculated] = useState(false)
	const [isMainLoading, setIsMainLoading] = useState(false)
	const [createUserLoading, setCreateUserLoading] = useState(false)
	const [userLoading, setUserLoading] = useState(false)
	const [itemLoading, setItemLoading] = useState(false)
	const [boxPlace, setBoxPlace] = useState('')
	const [userFields2, setUserFields2] = useState([])
	const [docDate, setDocDate] = useState(moment().format('YYYY-MM-DD'))

	const [comment, setComment] = useState('')
	const [userFields, setUserFields] = useState([])
	const [warehouses, setWarehouses] = useState([])
	const [toWarehouse, setToWarehouse] = useState(
		get(getMe, 'Department2.Name', '') !== 'Boshqaruv'
			? get(getMe, 'U_Warehouse', '')
			: '',
	)
	const [warehouseLoading, setWarehouseLoading] = useState(false)
	const [imageList, setImageList] = useState([
		{ file: null, path: '', imgLoading: false },
	])

	useEffect(() => {
		getSimpleMonth()
		getVIPMonth()
		userFieldsFn()
		getWarehouses()
		userFieldsOINVFn()
	}, [])

	const getWarehouses = () => {
		setWarehouseLoading(true)
		customMainApi
			.get('Warehouses?$select=WarehouseCode,WarehouseName')
			.then((res) => {
				setWarehouses(JSON.parse(res.data).value)
			})
			.catch((err) => {
				if (err)
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
			.finally(() => {
				setWarehouseLoading(false)
			})
	}

	const getSimpleMonth = () => {
		api
			.get('U_INSTCONFBASIC')
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setsimpleMonthData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM'",
					// $select: 'Name,ValidValuesMD,TableName',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}
	const userFieldsOINVFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields2(JSON.parse(res.data).value)
			})
	}

	const getVIPMonth = () => {
		api
			.get('U_INSTCONFVIP')
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setVipMonthData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
	}

	const searchUser = (a = '') => {
		setCustomer(a)
		const formattedName = a.includes("'") ? a.replace(/'/g, "''") : a
		setUserLoading(true)
		api
			.get(
				`BusinessPartners?$select=CardCode,CardName,Address,Phone1,U_Gender,U_PS,BPAddresses&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO' and (contains(CardName, '${formattedName}') or contains(Phone1, '${formattedName}'))`,
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setCustomerData(resData)
				const selectedCustomer = resData.find(
					(v) => get(v, 'CardName', '') === a,
				)
				if (selectedCustomer) {
					setUserCardCode(get(selectedCustomer, 'CardCode', ''))
				}
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
			.finally(() => {
				setUserLoading(false)
			})
	}

	const searchProduct = (name = '') => {
		const a = name
		setProductName(a)

		setItemLoading(true)
		customFuncsApi
			.get(
				`/xsSql/getItemsByWarehouse?${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `WhsCode='${get(getMe, 'U_Warehouse', '')}'&` : `WhsCode='${toWarehouse}'&`}ItemName=${a}&isOnHand=true`,
			)
			.then((res) => {
				const resData = get(res.data, 'value', [])
				setProductNameData(resData)
				// if (resData.length < 2) {
				// 	getSameSerialNumber(code)
				// 	setProductCode(code)
				// }
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
			.finally(() => {
				setItemLoading(false)
			})
	}

	const debouncedSearchUser = _.debounce((value) => searchUser(value), 1000)

	const debouncedSearchProduct = _.debounce(
		(value) => searchProduct(value),
		1000,
	)

	const getSameSerialNumber = (num) => {
		customFuncsApi
			.get(
				`/xsSql/getItemSerialsByWhsCode?ItemCode='${num}'${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `&WhsCode='${get(getMe, 'U_Warehouse', '')}'` : `&WhsCode='${toWarehouse}'`}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=1000000',
					},
				},
			)
			.then((res) => {
				const resData = get(res.data, 'value', [])
				setSeriaNumberData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить серийный номер. Повторите попытку.',
					),
				)
			})
	}

	const getOtherInformations = (v) => {
		const num = Number(v.split(') ')[0]) - 1
		setSeriaNumber(v)
		const code = v.split(') ')[1] || ''
		seriaNumberData.forEach((el, index) => {
			const IntrSerial = get(el, 'IntrSerial', '')

			if (IntrSerial === code) {
				setSeriaNumberMain(get(el, 'IntrSerial', ''))
				setSystemSerialNumber(get(el, 'SysNumber', ''))
			}
		})
		const dataO = seriaNumberData[num]

		setColor(get(dataO, 'U_Color', ''))

		setCondition(get(dataO, 'U_Condition', ''))
		setCurrentPrice(get(dataO, 'CostTotal', ''))
	}

	const getOtherInformationsfromSerialNumber = () => {
		customFuncsApi
			.get(
				`/xsSql/getItemsByWarehouse?${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `WhsCode='${get(getMe, 'U_Warehouse', '')}'&` : `WhsCode='${toWarehouse}'&`}IntrSerial='${seriaNumber}'`,
			)
			.then((res) => {
				const resData = get(res.data, 'value', [])
				setProductNameData(resData)
			})
			.catch((err) => {
				if (JSON.stringify(err, null, 2).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							err.response.data,
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
	}

	const addTable = () => {
		if (
			customer.length > 0 &&
			productName.length > 0 &&
			seriaNumber.length > 0 &&
			(month.length > 0 || monthB2B.length > 0)
		) {
			let monthNumber = monthB2B.length > 0 ? monthB2B : month
			monthNumber = Number(monthNumber.split(' oy')[0]) + 1

			setTableData([])

			for (let i = 0; i < monthNumber; i++) {
				let nextMonth = moment().clone().add(i, 'months')
				setTableData((pr) => [
					...pr,
					{
						date: nextMonth.format('YYYY-MM-DD'),
						price: 0,
						price2: 0,
					},
				])
			}
			setIsCalculated(true)
		} else {
			infoNotEndedRef.current?.open()
		}
	}

	useEffect(() => {
		if (month.length > 0 || monthB2B.length > 0) {
			addTable()
		}
	}, [month, monthB2B])

	const checkU_WHSCode = () => {
		if (get(getMe, 'Department2.Name', '') !== 'Boshqaruv') {
			if (get(getMe, 'U_Warehouse', null) === null) {
				alert(t('noWhsCode'))
			} else {
				createOrder()
			}
		} else {
			createOrder()
		}
	}

	const handleFileChange = (event, index) => {
		const updatedImageList = [...imageList]
		updatedImageList[index].file = event.target.files[0]
		setImageList(updatedImageList)
	}

	const uploadImg = async (index) => {
		const image = imageList[index]

		if (!image.file) {
			message.error(t('noPhoto'))
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = true
			setImageList(updatedImageList)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			const { key } = res.data
			updatedImageList[index].path = key
			setImageList(updatedImageList)

			message.success(t('sucUploaded'))
		} catch (err) {
			message.error(t('errorDownload'))
		} finally {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = false
			setImageList(updatedImageList)
		}
	}

	const viewImage = async (index) => {
		const image = imageList[index]

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: 'image/png',
				}),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error(t('errorDownload'))
		}
	}

	const addNewRow = () => {
		setImageList([...imageList, { file: null, path: '', imgLoading: false }])
	}
	const deleteRow = (index) => {
		setImageList(imageList.filter((_, i) => i !== index))
	}

	const createOrder = () => {
		const nowDays = new Date()
		let whichType = monthB2B.length > 0 ? vipMonthData : simpleMonthData
		const photoId = imageList
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		if (!isCash) {
			// let monthNumber = monthB2B.length > 0 ? monthB2B : month || ''
			// monthNumber = Number(monthNumber.split(' oy')[0])

			let protsent = monthB2B.length > 0 ? monthB2B : month || ''
			let splitProsent = protsent.split(' oy')

			protsent =
				1 +
				Number(
					whichType.find((item) => item?.U_Months == splitProsent[0])
						?.U_Percent,
				) /
					100
		}
		setIsMainLoading(true)

		const dataT = tableData.map((v) => {
			return {
				Total: v.price,
				DueDate: v.date,
			}
		})

		let body = {
			CardCode: userCardCode,
			DocDate: moment(docDate).format('YYYY-MM-DD'),
			Comments: comment,
			U_DaftarBet: U_DaftarBet,
			U_BoxPlace: boxPlace,
			SalesPersonCode: getMe.SalesPersonCode || -1,
			U_PhotoID: photoId,
			U_TypeInvoice: 'Naqd',
			DocumentLines: [
				{
					SerialNumbers: [
						{
							InternalSerialNumber: seriaNumberMain,
							SystemSerialNumber: systemSerialNumber,
						},
					],
					Quantity: 1,
					UnitPrice: price,
					ItemCode: productCode,
					...(get(getMe, 'Department2.Name', '') !== 'Boshqaruv'
						? { WarehouseCode: get(getMe, 'U_Warehouse', '') }
						: { WarehouseCode: toWarehouse }),
				},
			],
		}
		if (!isCash) Object.assign(body, { DocumentInstallments: dataT })
		api
			.post(`Invoices`, body)
			.then((res) => {
				const resData = JSON.parse(res.data)
				setViewData({
					CardName: get(resData, 'CardName', ''),
					DocEntry: get(resData, 'DocEntry', 0),
					CardCode: get(resData, 'CardCode', 0),
					Installmnt: 1,
					ItemDescription: productName,
					Comments: comment,
				})
				successModalRef.current?.open('Заказ успешно создан')
			})
			.catch((err) => {
				setIsMainLoading(false)
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const changeMainPrice = (v) => {
		let mainPrice = Number(v)
		setPrice(v)

		let dataT = mainPrice
		let A = (dataT - minusedMoney) / (Number(tableData.length) - 1)

		for (let i = 0; i < tableData.length; i++) {
			if (i !== 0) {
				let aa = [...tableData]
				aa[i].price = A
				setTableData(aa)
			} else {
				let aa = [...tableData]
				aa[0].price = minusedMoney
				setTableData(aa)
			}
		}
	}

	const changeDate = (v) => {
		let exampleDate = [...tableData]
		let day = v.slice(8, 10)

		for (let i = 0; i < exampleDate.length; i++) {
			let nextMonth = moment().clone().add(i, 'months').format('YYYY-MM-DD')
			let selectedDay = nextMonth.slice(0, 8) + day

			if (dateIndex <= i) {
				exampleDate[i].date = selectedDay
			}
		}
		setTableData(exampleDate)
		changeDateRef.current?.close()
	}

	return (
		<Layout>
			<CreateProductsStyle>
				<div className="container">
					{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ? (
						<div className="mb-5 flex items-start gap-3 sm:gap-10 flex-col sm:flex-row">
							<div>
								<div className={'flex items-center gap-1 w-full '}>
									<select
										id={'from-warehouse'}
										className={`bg-[#FAFBFC] border-[1px] ${
											!toWarehouse ? 'border-red-500' : 'border-[#DFE2E8]'
										} rounded-md p-3 text-[14px] text-[#1E1F1F] w-full sm:w-[315px]`}
										onChange={(e) => setToWarehouse(e.target.value)}
										value={toWarehouse}
										required
									>
										<option value={null}>{t('selectWhs')}</option>
										{warehouses.map((v, i) => (
											<option key={i} value={v.WarehouseCode}>
												{v.WarehouseName}
											</option>
										))}
									</select>
									<ClipLoader size={15} loading={warehouseLoading} />
								</div>
								{!toWarehouse && (
									<p className="text-red-500 text-sm">{t('whsRequired')}</p>
								)}
							</div>
							<div>
								<div className="w-full">
									<input
										type="date"
										value={docDate}
										onChange={(e) => {
											setDocDate(e.target.value)
										}}
										className={
											'border-[1px] p-2 border-[#DFE2E9"] rounded-md w-full'
										}
									/>
								</div>
							</div>
						</div>
					) : (
						''
					)}
					<div className="grid grid-cols-1 md:grid-cols-2 gap-7 lg:grid-cols-3 items-start">
						<div className="flex gap-2">
							<div className="relative w-full">
								<input
									type="text"
									list="client"
									placeholder={t('client')}
									disabled={!toWarehouse}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									defaultValue={customer}
									onChange={(v) => debouncedSearchUser(v.target.value)}
								/>
								{userLoading && (
									<div className="absolute top-3 right-9">
										<ClipLoader size={20} color="#0A4D68" />
									</div>
								)}

								<datalist id="client">
									{customerData.map((v, i) => (
										<option key={i} value={`${get(v, 'CardName', '')}`} />
									))}
								</datalist>
							</div>
							<button
								className="bg-[#0A4D68] text-white px-4 rounded-md"
								onClick={() => createUserRef.current?.open()}
								disabled={!toWarehouse}
							>
								+
							</button>
						</div>
						<div className="relative">
							<input
								type="text"
								list="productName"
								disabled={!toWarehouse}
								placeholder={t('product')}
								className="border-[1px] border-[#DFE2E9] p-2 rounded-md w-full"
								onChange={(v) => {
									debouncedSearchProduct(v.target.value)
									const selectedOption = productNameData.find((item) => {
										const itemName = get(item, 'ItemName', '')

										const color =
											get(item, 'U_Color', '') !== ''
												? userFields
														?.find((userField) => userField.Name === 'Color')
														?.ValidValuesMD.find(
															(value) =>
																get(item, 'U_Color', '') === value.Value,
														)?.Description || ''
												: ''
										const memory =
											get(item, 'U_Memory', '') !== ''
												? userFields
														?.find((userField) => userField.Name === 'Memory')
														?.ValidValuesMD.find(
															(value) =>
																get(item, 'U_Memory', '') === value.Value,
														)?.Description || ''
												: ''
										const condition =
											get(item, 'U_Condition', '') !== ''
												? userFields
														?.find(
															(userField) => userField.Name === 'Condition',
														)
														?.ValidValuesMD.find(
															(value) =>
																get(item, 'U_Condition', '') === value.Value,
														)?.Description || ''
												: ''
										const formattedValue = [itemName, color, memory, condition]
											.filter(Boolean)
											.join(' ')
										return formattedValue === v.target.value
									})

									if (selectedOption) {
										const itemCode = get(selectedOption, 'ItemCode', '')
										setProductCode(itemCode)
										getSameSerialNumber(itemCode)
									}
								}}
							/>
							{itemLoading && (
								<div className="absolute top-3 right-9">
									<ClipLoader size={20} color="#0A4D68" />
								</div>
							)}
							<datalist id="productName">
								{productNameData.map((v, i) => {
									const itemName = get(v, 'ItemName', '')

									const color =
										get(v, 'U_Color', '') !== ''
											? userFields
													?.find((item) => item.Name === 'Color')
													?.ValidValuesMD.find(
														(value) => get(v, 'U_Color', '') === value.Value,
													)?.Description || ''
											: ''
									const memory =
										get(v, 'U_Memory', '') !== ''
											? userFields
													?.find((item) => item.Name === 'Memory')
													?.ValidValuesMD.find(
														(value) => get(v, 'U_Memory', '') === value.Value,
													)?.Description || ''
											: ''
									const condition =
										get(v, 'U_Condition', '') !== ''
											? userFields
													?.find((item) => item.Name === 'Condition')
													?.ValidValuesMD.find(
														(value) =>
															get(v, 'U_Condition', '') === value.Value,
													)?.Description || ''
											: ''
									const formattedValue = [itemName, color, memory, condition]
										.filter(Boolean)
										.join(' ')
									return (
										<option key={i} value={`${formattedValue}`}>
											{`${formattedValue}`}
										</option>
									)
								})}
							</datalist>
						</div>

						<div className="flex gap-2">
							<input
								type="text"
								list="seriaNumber"
								placeholder={t('serialNum')}
								disabled={!toWarehouse}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={seriaNumber}
								onChange={(v) => getOtherInformations(v.target.value)}
							/>
							<datalist id="seriaNumber">
								{seriaNumberData
									.filter((v) => get(v, 'IntrSerial', '')) // Filter out entries where IntrSerial is null or empty
									.map((v, i) => (
										<option
											key={i}
											value={`${i + 1}) ${get(v, 'IntrSerial', '')}`}
										/>
									))}
							</datalist>
							<button
								className="bg-[#0A4D68] text-white px-4 rounded-md"
								onClick={getOtherInformationsfromSerialNumber}
								disabled={!toWarehouse}
							>
								<BsSearch />
							</button>
						</div>
						<input
							type="text"
							placeholder={t('color')}
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={
								color !== ''
									? userFields
											?.find((item) => item.Name === 'Color')
											?.ValidValuesMD.find((value) => color === value.Value)
											?.Description || ''
									: ''
							}
							// onChange={(v) => setColor(v.target.value)}
							disabled={true}
						/>
						<input
							type="text"
							placeholder={t('condition')}
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={condition}
							disabled={true}
						/>
						<input
							type="number"
							placeholder={t('currentPrice')}
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							onChange={(v) => setCurrentPrice(v.target.value)}
							defaultValue={currentPrice}
							disabled={true}
						/>
						<input
							type="number"
							placeholder={t('price2')}
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={price}
							disabled={!toWarehouse}
							onChange={(v) => changeMainPrice(v.target.value)}
						/>
						<textarea
							placeholder={t('comment')}
							className={
								'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full textarea'
							}
							value={comment}
							disabled={!toWarehouse}
							onChange={(v) => setComment(v.target.value)}
						/>
						<input
							type="text"
							placeholder={t('pageNumber')}
							disabled={!toWarehouse}
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={U_DaftarBet}
							onChange={(v) => setU_DaftarBet(v.target.value)}
						/>
						<div className="flex items-center gap-3">
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-full'
								}
								onChange={(event) => setBoxPlace(event.target.value)}
								value={boxPlace}
							>
								<option value="" disabled>
									Коробка
								</option>

								{userFields2
									?.find((item) => item.Name === 'BoxPlace')
									?.ValidValuesMD.filter((value) => {
										return (
											value.Description === 'Bor' || value.Description === 'Yoq'
										)
									})
									.map((value) => (
										<option key={value.Value} value={value.Value}>
											{value.Description}
										</option>
									))}
							</select>
						</div>
					</div>

					<div className="w-full sm:w-[600px]">
						{imageList.map((image, index) => (
							<div
								key={index}
								className="flex flex-col w-full items-start gap-3 mt-5 mb-10"
							>
								<div>
									<p className="font-bold">
										{t('photo')}-{index + 1}
									</p>
								</div>
								<div className="flex items-center gap-3 flex-col sm:flex-row">
									<Input
										type="file"
										onChange={(e) => handleFileChange(e, index)}
										className="w-full"
										disabled={!toWarehouse}
									/>
									<AntButton
										onClick={() => uploadImg(index)}
										isLoading={image.imgLoading}
										disabled={!toWarehouse}
										className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
									>
										{t('upload')}
									</AntButton>
									{image.path && (
										<AntButton
											onClick={() => viewImage(index)}
											disabled={!toWarehouse}
											className="h-10 w-full  sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
										>
											{t('download')}
										</AntButton>
									)}
									{index !== 0 && (
										<AntButton
											onClick={() => deleteRow(index)}
											disabled={!toWarehouse}
											className="h-10 w-full sm:w-[100px] bg-red-600 rounded-lg p-2 bg-red-500 text-white"
										>
											{t('delete')}
										</AntButton>
									)}

									{index === imageList.length - 1 && (
										<AntButton
											onClick={addNewRow}
											disabled={!toWarehouse}
											className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
										>
											{t('add2')}
										</AntButton>
									)}
								</div>
							</div>
						))}
					</div>

					<div className="flex gap-3 mt-8 justify-end w-full">
						<Button
							btnStyle={{ backgroundColor: 'red' }}
							onClick={() => navigate('/cash-payment')}
						>
							{t('back')}
						</Button>
						<Button
							onClick={checkU_WHSCode}
							isLoading={isMainLoading}
							disabled={!toWarehouse}
						>
							{t('create')}
						</Button>
					</div>
				</div>
			</CreateProductsStyle>
			<>
				<CreateUserModal
					isLoading={createUserLoading}
					getRef={(r) => (createUserRef.current = r)}
					onConfirm={() => succesCreateUserRef.current?.open(t('sucClient'))}
				/>
				<ErrorModal
					getRef={(r) => (errorRef.current = r)}
					onConfirm={() => {
						setIsMainLoading(false)
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						succesCreateUserRef.current = r
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						successModalRef.current = r
					}}
					onConfirm={() => {
						const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(viewData))}&DocEntry=${viewData.DocEntry}`
						navigate(url)
					}}
				/>

				<ChangeDate
					getRef={(r) => {
						changeDateRef.current = r
					}}
					onConfirm={(v) => changeDate(v)}
				/>

				<WarningModal
					getRef={(ref) => {
						infoNotEndedRef.current = ref
					}}
				/>
			</>
		</Layout>
	)
}

export default CreateProducts
