import React, { useState, useEffect, useRef } from 'react'
import customMainApi from '../../../../api'
import { get } from 'lodash'
import { ErrorModal } from '../../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import Button from '../../../../components/Button/index'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next';

export default function VendorPayments() {
	const errorRef = useRef()
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState(false)
	const [currentPage, setCurrentPage] = useState(0)
	const [data, setData] = useState([])

	useEffect(() => {
		search()
	}, [currentPage])

	const newDatas = () => {
		if (data.length >= 10) {
			setCurrentPage(currentPage + 10)
		} else {
			alert(t("noOtherInfo"))
		}
	}
	const oldData = () => {
		if (data.oldPage < 0) {
			alert(t("noOtherInfo"))
		} else {
			setCurrentPage(currentPage - 10)
		}
	}
	const search = () => {
		setIsLoading(true)
		customMainApi
			.get(`VendorPayments?$orderby=DocNum desc&$skip=${currentPage}`, {
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			})
			.then((res) => {
				setData(JSON.parse(res.data).value)
				setIsLoading(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}
	return (
		<>
			<div>
				{isLoading ? (
					<ClipLoader
						loading={true}
						size={75}
						aria-label="Loading Spinner"
						className={'loader'}
						data-testid="loader"
					/>
				) : (
					<>
						<div className="overflow-y-auto my-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											{t("docNum")}
										</th>
										<th scope="col" className="px-6 py-4">
											{t("fromAcc")}
										</th>

										<th scope="col" className="px-6 py-4">
											{t("price")}
										</th>
										<th scope="col" className="px-6 py-4">
											{t("dateOfSale")}
										</th>
									</tr>
								</thead>
								<tbody>
									{data.length > 0 ? (
										data.map((v, i) => (
											<tr
												key={i}
												className="bg-white border-b  hover:bg-gray-50 "
											>
												<td className="px-6 py-4 font-medium text-gray-900 ">
													{get(v, 'DocNum', '-')}
												</td>
												<td className="px-6 py-4 font-medium text-gray-900 ">
													{get(v, 'CashAccount', '')}
												</td>
												<td className="px-6 py-4 font-medium text-gray-900 ">
													{new Intl.NumberFormat('fr-FR', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													})
														.format(get(v, 'CashSum', '-'))
														.replace(',', '.')}
												</td>

												<td className="px-6 py-4 font-medium text-gray-900 ">
													{moment(get(v, 'DocDate', '-')).format('YYYY-MM-DD')}
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description={t("noInfo")}
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div className="topCard">
							<div className="flex items-center gap-2 ">
								<Button
									disabled={currentPage === 0}
									className={'btn'}
									onClick={oldData}
								>
									{'<'}
								</Button>
								<p>{currentPage / 10 + 1 || 1}</p>
								<Button
									disabled={data.length < 10}
									className={'btn'}
									onClick={newDatas}
								>
									{'>'}
								</Button>
							</div>
						</div>
					</>
				)}
			</div>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</>
	)
}
